import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { throwError } from 'rxjs';


// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: any;

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {
    datalayerScriptstring: string;
    constructor(
        private readonly conf: ConfigService,
    ) { }

    public initialize(): void {
        // dynamically add analytics scripts to document head
        try {
            const url = 'https://www.googletagmanager.com/gtag/js?id=';
            const gTagScript = document.createElement('script');
            gTagScript.async = true;
            gTagScript.src = `${url}${this.conf.config.googleAnalyticsKey}`;
            document.head.appendChild(gTagScript);

            this.datalayerScriptstring = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${url}${this.conf.config.googleAnalyticsKey}', {'send_page_view': false});`;

            if(this.conf.hasGoogleAnalytics4) {
                const urlGA4 = 'https://www.googletagmanager.com/gtag/js?id=' + this.conf.googleAnalytics4Key;
                const gTagScriptGA4 = document.createElement('script');
                gTagScriptGA4.async = true;
                gTagScriptGA4.src = `${urlGA4}`;
                document.head.appendChild(gTagScriptGA4);
            }
            const dataLayerScript = document.createElement('script');
            dataLayerScript.innerHTML = this.datalayerScriptstring;
            document.head.appendChild(dataLayerScript);
        } catch (e) {
            throwError('Error adding Google Analytics', e);
        }
    }

    // use gtag.js to send Google Analytics Events
    public event(action: string, eventCategory?: string, eventLabel?: string, value?: string) : void {
        gtag('event', action, {
            ...(eventCategory && { event_category: eventCategory }),
            ...(eventLabel && { event_label: eventLabel }),
            ...(value && { value }),
        });
    }
}