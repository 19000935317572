import { SerialNumberComponent } from './serial-number.component';
import { mapToNull, NavigationService, pathify } from '@aex/ngx-toolbox';
import {
	AfterViewChecked,
	ChangeDetectorRef,
	Component,
	OnInit,
	QueryList,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { defer, flatMap, isNil } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError, zip } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { APP_ROUTES } from '../../../../_shared/types';
import { FilenameUtils } from '../../../../_shared/utils';
import { ConfigService } from '../../../../services/config.service';
import { InstallService } from '../../../../services/install.service';
import { MapService } from '../../../../services/map.service';
import {
	IFnoConfiguration,
	IInstall,
	InstallType,
	ImageUpload,
	IStockRequirement,
	IHasErrors,
	IKeyValuePair,
	ICustomPhrases,
} from '../../../../services/types';
import { UploadService } from '../../../../services/upload.service';
import { installTypeToStr } from '../../../../services/utils';
import { SignatureFieldComponent } from './signature-field.component';
import { StockService } from '../../../../services/stock.service';
import { Guid } from 'guid-typescript';

@Component({
templateUrl: './install-begin.component.html',
// eslint-disable-next-line @angular-eslint/relative-url-prefix
styleUrls: ['../install-action.scss', './install-begin.component.scss'],
})
export class InstallBeginComponent implements AfterViewChecked, OnInit {

	@ViewChild(SignatureFieldComponent, {static: false}) public signatureCmp: SignatureFieldComponent;
	@ViewChildren('part') public installParts: QueryList<IHasErrors>;
	@ViewChild(SerialNumberComponent, {static: false}) public serialNumberComponent: SerialNumberComponent;

	public readonly conf: IFnoConfiguration;
	public install: IInstall;
	public stockRequirements: IStockRequirement;
	public serviceAssets: IStockRequirement;
	public canEdit = false;
	public customSurvey = false;
	public solarInstallPreCheck = false;
	public inProgressStatusId: string;
	public isAmerican: boolean;
	public layer3Enabled: boolean;
	public installType: string;
	public showPortNumber: boolean;
	public finishStatuses: IKeyValuePair<string, Guid>[] = [];
	public finishStatusId: string;

	constructor( // NOSONAR - SQ complains about too many injected services
		private readonly geolocation: MapService,
		private readonly router: Router,
		private readonly uploadService: UploadService,
		private readonly nav: NavigationService,
		private readonly installService: InstallService,
		private readonly toast: ToastrService,
		private readonly cdr: ChangeDetectorRef,
		public readonly route: ActivatedRoute,
		public readonly configService: ConfigService,
		private readonly stockService: StockService,
	) {
		this.conf = configService.config;
		route.data.subscribe(({workOrder, stockRequirements, serviceAssets}) => {
			this.install = workOrder;
			this.stockRequirements = stockRequirements;
			this.serviceAssets = serviceAssets;
			this.refreshLocation();
		});
		this.customSurvey = this.conf.showCustomSurvey;
		this.solarInstallPreCheck = this.conf.showSolarInstallPreCheck;
		this.isAmerican = !!this.configService.config.customPhrases?.distanceMetric;
		this.layer3Enabled = this.conf.displayLayer3 && this.install.type_id !== InstallType.NIDINSTALL;
		this.showPortNumber = this.conf.showPortNumber && this.install.type_id !== InstallType.NIDINSTALL;
		this.finishStatuses = this.configService.mapToKeyValuePair(this.configService.finishStatus(this.install.type_id));
	}

	public get fsanPhrase(): string {
		return this.conf.customPhrases?.fsan ?? 'FSAN';
	}

	public get hasMultipleFinishStatuses(): boolean {
		return this.finishStatuses.length > 1;
	}

	public get isInProgress(): boolean {
		return Guid.parse(this.install?.status_id).equals(this.configService.beginStatus(this.install.type_id));
	}

	public get hasSignature(): boolean {
		return this.signatureCmp?.hasSignature;
	}
	public get isNidInstallation(): boolean {
		return (this.install.type_id === InstallType.NIDINSTALL);
	}

	public get isNotRepair(): boolean {
		return (this.install.type_id !== InstallType.REPAIR && this.install.type_id !== InstallType.PREMIUM_SUPPORT_REPAIR && !this.isNidInstallation );
	}

	public ngAfterViewChecked(): void {
		// Ensures updates to child views don't cause 'Expression changed after checked' error
		this.cdr.detectChanges();
	}

	public ngOnInit(): void {
		if (this.install.type_id !== InstallType.NIDINSTALL)
			this.stockService.getInstallTypeValues(this.install.id)
				.subscribe(stock => {
					if (stock?.attributes.length > 0) {
						const attributes = stock?.attributes as any[];
						this.installType = attributes.find(res => res.install_type)?.install_type;
					}
				});
}

	public clearSignature(): void {
		this.signatureCmp.clear();
	}

	public refreshLocation(): void {
		this.geolocation.isCloseEnough(this.install, this.conf.proximity, true)
			.subscribe(closeEnough => this.canEdit = closeEnough);
	}


	public submitSignature(): void {
		this.nav.startLoading();
		const closeEnoughObs = isNil(this.conf.proximity)
			? of(true)
			: this.geolocation.isCloseEnough(this.install, this.conf.proximity).pipe(
				tap(closeEnough => this.canEdit = closeEnough),
				switchMap(closeEnough => closeEnough ? of(closeEnough) : throwError(new Error('You are not close enough to sign this off'))),
			);

		closeEnoughObs.pipe(
			switchMap(() => this.uploadService.uploadFile(new ImageUpload(
				this.signatureCmp.signature,
				null,
				`signature_${FilenameUtils.getTimestampFilename('signature.png')}`,
				pathify('work_orders', this.install.reference, 'signature'),
			))),
			switchMap(() => this.finishInstall()),
			finalize(() => defer(() => this.nav.stopLoading())),
		).subscribe();
	}

	private finishInstall(): Observable<void> {
		const obj = {
			status_id: this.finishStatusId ?? Object.values(this.configService.finishStatus(this.install.type_id))[0].toString(),
			comments: `Finalising ${installTypeToStr(this.install.type_id, this.configService.config.customPhrases)}`,
		};

		return this.installService.updateInstall(this.install.id, this.install.type_id, obj).pipe(
			tap(response => {
				if (response.status_id === obj.status_id) {
					this.toast.success(`${installTypeToStr(this.install.type_id, this.configService.config.customPhrases)} completed`);
					this.router.navigateByUrl(APP_ROUTES.installs.path).then();
				} else
					this.toast.warning(`Install not completed, please refresh and try again`);
			}),
			mapToNull(),
		);
	}


	public clickToBegin(): void {
		this.beginInstall();
	}

	public translate(value: string): string {
		return this.configService.config.customPhrases[value as keyof ICustomPhrases];
	}

	private beginInstall(): void {
		const obj = {
			status_id: this.configService.beginStatus(this.install.type_id).toString(),
			comments: `Setting ${installTypeToStr(this.install.type_id, this.configService.config.customPhrases)} to In Progress`,
		};

		zip(
			this.installService.updateInstall(this.install.id, this.install.type_id, obj),
			this.installService.getExtraInstallInfo(this.install, this.installType),
		).subscribe(([updateResponse]) => {
			if (updateResponse.status_id === obj.status_id)
				this.toast.success(`${installTypeToStr(this.install.type_id, this.configService.config.customPhrases)} started`);
			else
				this.toast.warning(`Install not started correctly, please refresh and try again`);
		});
	}

	public get hasErrors(): boolean {
		return !this.errorMessages || !!this.errorMessages.length;
	}

	public get errorMessages(): string[] {
		return this.installParts ? flatMap(this.installParts.map(p => p.errors)) : [];
	}

	public isFsanValid(): boolean {
		return (this.isNidInstallation || this.serialNumberComponent?.serialNumberValid || this.configService.config.disableFsanValidation);
	}

	public disableFsanValidation(): boolean {
		return (this.configService.config.disableFsanValidation && !this.isNidInstallation);
	}
}
