import { ILoaderDef, IPagedResponse } from '@aex/ngx-toolbox';
import { LoaderType } from '@aex/ngx-toolbox/lib/toolbox/navigation.service';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { flatten } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ParamMetaData } from '../_shared/param-meta-data';
import { FilesApi } from './api';
import { FileUploadProgress, FileUploadStatus, ImageUpload, IUploadedImage, newFileUploadProgress } from './types';

@Injectable({providedIn: 'root'})
export class UploadService {

	private readonly filesChangedSubject = new Subject<void>();
	public readonly filesChangedEvent = this.filesChangedSubject.asObservable();

	constructor(
			private readonly http: HttpClient,
			private readonly toast: ToastrService,
	) { }

	public downloadFile(fileId: number, filename: string, loader?: LoaderType): void {
		this.http.get(FilesApi.download(fileId), {
			responseType: 'blob',
			params: new ParamMetaData({loader}),
		}).subscribe(result => {
			saveAs(result, filename);
		});
	}

	// Get uploaded files for a list of namespaces
	public getFiles(loader: ILoaderDef, ...namespaces: string[]): Observable<IUploadedImage[]> {
		return forkJoin(namespaces.map(namespace => {
			return this.http.get<IPagedResponse<IUploadedImage>>(FilesApi.files, {
				params: new ParamMetaData({handleError: 'files', loader}, {namespace}),
			}).pipe(map(response => response.items));
		})).pipe(map(list => flatten(list)));
	}

	public uploadFiles(files: ImageUpload[], namespace?: string, processResponse?: UploadResponseProcessor): FileUploadProgress {
		const result = newFileUploadProgress();
		files.forEach(file => {
			const progress = new Subject<number>();
			result.set(file.guid, progress.asObservable());
			file.status = FileUploadStatus.IN_PROGRESS;

			this.http.post(namespace ?? FilesApi.files, file.getFormData(), {
				reportProgress: true,
				observe: 'events',
				params: new ParamMetaData({loader: false}),
			}).pipe(
					catchError(error => {
						const message = `Could not upload file: ${ file.filename }`;
						this.toast.error(message);
						console.error(message, error);
						file.status = FileUploadStatus.ERROR;
						progress.complete();
						return EMPTY;
					}),
					switchMap(event => {
						return event instanceof HttpResponse && processResponse
								? processResponse(file, event).pipe(map(() => event))
								: of(event);
					}),
			).subscribe(event => {
				if (event instanceof HttpResponse) {
					file.status = FileUploadStatus.UPLOADED;
					progress.complete();
				} else if (event.type === HttpEventType.UploadProgress)
					progress.next(Math.round(event.loaded / event.total * 100));
			});
		});
		return result;
	}

	public deleteFile(id: string, loader?: LoaderType): Observable<void> {
		return this.http.delete<void>(FilesApi.file(id), {params: new ParamMetaData({loader})});
	}

	public uploadFile(file: ImageUpload): Observable<void> {
		return this.http.post<void>(FilesApi.file(file.namespace), file.getFormData(false));
	}

	public filesChanged(): void {
		this.filesChangedSubject.next();
	}

	public uploadRicaFile(file: ImageUpload, namespace: string): Observable<void> {
		return this.http.post<void>(FilesApi.fileLocation(namespace), file.getFormData(false));
	}

}


export type UploadResponseProcessor = (file: ImageUpload, response: HttpResponse<any>) => Observable<void>;