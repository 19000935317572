<div fxLayout="column" fxLayoutGap=".5rem" fxFlexAlign="center">
    <div>
        <br>
        <div fxLayout="row wrap" fxLayoutGap="1rem" fxLayoutAlign="center">
            <mat-form-field>
                <input matInput type="number" [(ngModel)]="portNumber" name="portNumber" maxlength="3"
                       placeholder="Port Number" required>
            </mat-form-field>
            <br>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="1rem" fxLayoutAlign="center" class="wrapped-button-container">
            <button mat-flat-button color="primary" type="button" fxFlexAlign="center"
                    (click)="refreshPortNumber()">
                <fa-icon icon="sync-alt"></fa-icon>
                <span> Refresh </span>
            </button>
            <button mat-flat-button color="primary" type="button" fxFlexAlign="center"
                    (click)="savePortNumber()">
                <fa-icon icon="cloud-upload-alt"></fa-icon>
                <span> Save </span>
            </button>
        </div>
    </div>
</div>