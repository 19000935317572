import { WithDestroy } from '@aex/ngx-toolbox';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getDay } from 'date-fns';
import { partialRight, sortBy } from 'lodash';
import { TSMap } from 'typescript-map';
import { ConfigService } from '../../services/config.service';
import { FrameworkService } from '../../services/framework.service';
import { InstallService } from '../../services/install.service';
import {
	DateFilterType,
	IFilteredInstalls,
	IFnoConfiguration,
	IInstall,
	IInstallFilter,
	InstallStatusFilter,
	InstallType,
} from '../../services/types';
import { installTypeToStr } from '../../services/utils';

@Component({
	selector: 'app-installs',
	templateUrl: './installs.component.html',
	styleUrls: ['./installs.component.scss'],
})

export class InstallsComponent extends WithDestroy() {
	public readonly conf: IFnoConfiguration;
	public readonly DateFilterType = DateFilterType;
	public readonly InstallType = InstallType;
	public readonly InstallStatus = InstallStatusFilter;
	public readonly businessDayFilter = partialRight(materialBusinessDayFiler, this.configService.config.includeWeekend);

	public filter: IInstallFilter = this.installService.filter;
	public statusFilter = new TSMap<InstallStatusFilter, boolean>();
	public filteredInstalls: IInstall[] = [];
	public showPreOrder: boolean;
	public showRelocation: boolean;
	public showFault: boolean;
	public showPremiumRepair: boolean;
	public showPremiumFault: boolean;
	public showNidInstalls: boolean;

	private _installs: IFilteredInstalls;
	public get installs(): IFilteredInstalls { return this._installs; }

	public set installs(value: IFilteredInstalls) {
		this._installs = value;
		this.setSortedInstalls();
	}

	constructor(
		private readonly installService: InstallService,
		private readonly configService: ConfigService,
		frameworkService: FrameworkService,
		route: ActivatedRoute,
	) {
		super();
		this.noZombie(installService.filterStream)
			.subscribe(filter => frameworkService.title = installTypeToStr(filter.installType, this.configService.config.customPhrases, true));
		route.data.subscribe(({ installs }) => this.installs = installs);

		this.showPreOrder = this.configService.config.showPreOrder ?? false;
		this.showRelocation = this.configService.config.showRelocation ?? false;
		this.showFault = this.configService.config.showFault ?? false;
		this.showPremiumRepair = this.configService.config.showPremiumRepair ?? false;
		this.showPremiumFault = this.configService.config.showPremiumFault ?? false;
		this.showNidInstalls = this.configService.config.showNidInstalls ?? false;
	}

	public filterChanged(): void {
		this.filter = this.installService.saveFilter(this.filter);
		this.installs = this.installService.getLocalInstalls(this.filter);
		this.refreshInstalls();
	}

	public get installStatus(): InstallStatusFilter {
		return this.statusFilter.keys().find(k => this.statusFilter.get(k));
	}

	public filterByStatus(status: InstallStatusFilter): void {
		this.statusFilter.set(status, !this.statusFilter.get(status));
		this.statusFilter.keys().filter(k => k !== status).forEach(k => this.statusFilter.set(k, false));
		this.setSortedInstalls();
	}

	private setSortedInstalls(): void {
		const selectedStatus = this.installStatus;
		const installs = this.installs?.installs?.filter(i => !selectedStatus || i.status === selectedStatus) ?? [];
		this.filteredInstalls = sortBy(installs, i => new Date(i.schedule_date));
	}

	public refreshInstalls(): void {
		this.installService.getInstalls(this.filter).subscribe(i => this.installs = i);
	}

	public get repairPhrase(): string {
		return this.configService.config.customPhrases.Repairs;
	}
	public get premiumRepairPhrase(): string {
		return this.configService.config.customPhrases.PremiumRepairs;
	}

}

function materialBusinessDayFiler(date: Date, includeWeekends?: boolean): boolean {
	if (includeWeekends) return true;
	const day = getDay(date);
	// Prevent Saturday and Sunday from being selected.
	return day !== 0 && day !== 6;
}
