<div class="image-upload-container mat-elevation-z2 rica">

	<div *ngIf="isVerified; else notVerified" class="success">
		<fa-icon icon="check-circle" size="6x"></fa-icon>
		<div>Rica has been Uploaded and Verified</div>
	</div>

	<ng-template #notVerified>

		<mat-vertical-stepper [linear]="true" #stepper>
			<mat-step [editable]="false">
				<ng-template matStepLabel>Upload ID / Proof of Residence</ng-template>
				<div class="uploaded-files">
					<div *ngIf="hasIdDocument">
						<fa-icon icon="check-circle" class="verified"></fa-icon>
						ID Uploaded
					</div>
					<div *ngIf="hasPorDocument">
						<fa-icon icon="check-circle" class="verified"></fa-icon>
						Proof of Residence Uploaded
					</div>
				</div>

				<app-image-upload [imageTypes]="imageTypes" [namespace]="namespace" [processUploadResponse]="processUploadResponse" [uploadToNamespace]="true"></app-image-upload>

				<button mat-flat-button color="primary" type="button" *ngIf="hasIdDocument && hasPorDocument" (click)="stepper.next()">Next</button>
			</mat-step>
			<mat-step [editable]="false">
				<form #step2=ngForm>
					<ng-template matStepLabel>Visual Identification</ng-template>
					<mat-form-field>
						<input maxlength="13" minlength="13" matInput placeholder="Installer ID" name="verifiedById" [ngModel]="verifiedById" required>
					</mat-form-field>
					<div>
						<button mat-button matStepperNext [disabled]="!step2.valid" (click)="submitRica()">Confirm</button>
					</div>
				</form>
			</mat-step>
			<mat-step>
				<ng-template matStepLabel>Done</ng-template>
			</mat-step>
		</mat-vertical-stepper>
	</ng-template>

</div>
