import { Pipe, PipeTransform } from '@angular/core';
import { InstallType } from '../services/types';
import { installTypeToStr } from '../services/utils';
import { ConfigService } from '../services/config.service';


/*
 * Convert install type into text.
 * Takes an plural argument that adds an s.
 * Usage:
 *   type | fileType:plural
 * Example:
 *   {{ InstallType.REPAIR | installType}}
 *   formats to: Repair
*/
@Pipe({name: 'installType'})
export class InstallTypePipe implements PipeTransform {
	constructor(
		private readonly configService: ConfigService,
	) {
	}
	public transform(type: InstallType, plural: boolean = false): string {
		return installTypeToStr(type, this.configService.config.customPhrases, plural);
	}

}
