<!-- Install - Re-Assign Form-->
<div class="install-action-container mat-elevation-z2">
    <form (ngSubmit)="reassignWorkOrder()" fxLayout="column" fxLayoutGap="1rem">
        <mat-form-field>
            <mat-label>Assigned Company</mat-label>
            <mat-select [(ngModel)]="selectedCompany" name="company" (selectionChange)="onCompanyChange($event)">
                <mat-option *ngFor="let company of companies" [value]="company">
                    {{company.assigned}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Assigned Person</mat-label>
            <mat-select [(ngModel)]="selectedCompanyPerson" name="companyPerson" (selectionchange)="onCompanyPersonChange($event)">
                <mat-option *ngFor="let person of companyPeople" [value]="person">
                    {{person.assigned_user}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!-- Comments Input -->
        <mat-form-field>
            <textarea id="CommentsField" matInput placeholder="Comments" name="comments" [(ngModel)]="comments" #CommentsField=ngModel></textarea>
        </mat-form-field>
        <button mat-flat-button color="primary" type="submit" class="icon-inside" fxFlexAlign="middle">
            <fa-icon [icon]="['far', 'clock']"></fa-icon>
            <span>Re-Assign {{ install.type_id | installType }}</span>
        </button>
    </form>
</div>