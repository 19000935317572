import { IEnvironmentBase } from '@aex/ngx-toolbox';

export interface IEnvironment extends IEnvironmentBase {
	override?: Partial<IEnvironment>
	operator?: string
	serverUrl: string
	assetsServerUrl: string
	securityServiceUrl: string
	purchaseServiceUrl: string
	googleMapsKey: string
}

const TO_BE_REPLACED = 'to-be-replaced';

export function env(inbound?: Partial<IEnvironment>): IEnvironment {
	return {
		name: 'dev',
		version: require('../../package.json').version,
		useServiceWorker: true,
		production: false,
		mocksEnabled: false,
		serverUrl: TO_BE_REPLACED,
		assetsServerUrl: TO_BE_REPLACED,
		securityServiceUrl: TO_BE_REPLACED,
		purchaseServiceUrl: TO_BE_REPLACED,
		googleMapsKey: ENV_GOOGLE_MAPS_KEY.default,
		...(inbound ?? {}),
	};
}

export const ENV_GOOGLE_MAPS_KEY = {
	default: 'AIzaSyAnVKGii19eNaNd0NFW2LQK2PIiwCxFwu8',
};
