<div class="auth-container mat-elevation-z2">
	<div class="login-info">
		<h1>AEx Connect Login</h1>
		<img src="./assets/icons/icon-72x72.png" alt="AEx Connect Logo"/>
	</div>
	<form (ngSubmit)="handleLogin(f)" #f="ngForm">
		<mat-form-field appearance="standard">
			<mat-label>Email Address</mat-label>
			<input
					type="email"
					matInput
					placeholder="user@fno.co.za"
					name="username"
					email
					ngModel
					required
					#emailField=ngModel
			/>
			<mat-error *ngIf="emailField.errors?.required">Email is required</mat-error>
			<mat-error *ngIf="emailField.errors?.email">Please enter a valid email address</mat-error>
		</mat-form-field>
		<mat-form-field appearance="standard">
			<mat-label>Password</mat-label>
			<input
					type="password"
					matInput
					placeholder="Password"
					name="password"
					minlength="6"
					ngModel
					required
					#passwordField=ngModel
			/>
			<mat-error *ngIf="passwordField.errors?.required">Password is required</mat-error>
			<mat-error *ngIf="passwordField.errors?.minlength">Password is no long enough</mat-error>
		</mat-form-field>
		<div class="button-container">
			<button mat-flat-button color="primary" type="submit">Login</button>
			<button *ngIf="allowMicrosoftLogin" mat-flat-button color="primary" (click)="loginWithMicrosoft()" type="button">
				<img src="./assets/img/microsoft-logo.png" alt="logo" class="button-image"/>
				<span>Login with Microsoft</span>
			</button>
		</div>
	</form>
</div>
