<div>
    <strong>Details</strong>
</div>
<br />
<div fxLayout="column" fxLayoutGap=".5rem" fxFlexAlign="center">
    <div fxFlexAlign="center">
        <mat-checkbox color="primary" [(ngModel)]="nid">NID Installed</mat-checkbox>
    </div>
    <div fxFlexAlign="center">
      <app-barcode-scanner [referrence]="id" [(serialNumber)]="assetReference"></app-barcode-scanner>
    </div>
    <div fxFlexAlign="end">
        <button mat-flat-button color="primary" type="button" (click)="save()">
            <span>
                <fa-icon icon="cloud-upload-alt"></fa-icon> 
                Save
            </span>
        </button>
    </div>
</div>
<div fxLayout="column" fxLayoutGap=".5rem" fxFlexAlign="center">
    <div fxFlexAlign="start">
        <strong>PPPoE Information</strong>
    </div>
    <div fxFlexAlign="start">
        Username: {{ (pppoeUsername?.length>25)? (pppoeUsername | slice:0:25)+'... ':(pppoeUsername)}}
        <fa-icon class="copy" *ngIf="pppoeUsernameExists" icon="copy" size="sm" (click)="copyToClipboard(pppoeUsername)"></fa-icon>
    </div>
    <div fxFlexAlign="start">
        Password: {{ pppoePassword }}
        <fa-icon class="copy" *ngIf="pppoePasswordExists" icon="copy" size="sm" (click)="copyToClipboard(pppoePassword)"></fa-icon>
    </div>
    <br />
    <div fxFlexAlign="start">
        <strong>Wi-Fi Information</strong>
    </div>
    <div fxFlexAlign="start">
        SSID: {{ wifiUsername }}
        <fa-icon class="copy" *ngIf="wifiUsernameExists" icon="copy" size="sm" (click)="copyToClipboard(wifiUsername)"></fa-icon>
    </div>
    <div fxFlexAlign="start" *ngIf="!enableOpenSSID">
        Password: {{ wifiPassword }}
        <fa-icon class="copy" *ngIf="wifiPasswordExists" icon="copy" size="sm" (click)="copyToClipboard(wifiPassword)"></fa-icon>
    </div>
    <div fxFlexAlign="center">
        <mat-checkbox color="primary" [(ngModel)]="enableOpenSSID">Enable Open SSID</mat-checkbox>
    </div>
    <br />
    <div fxFlexAlign="end">
        <button mat-flat-button
                color="primary"
                type="button"
                (click)="configureDevice()"
                [disabled]="!fsanExists || deviceConfigured">
            <span><fa-icon icon="sync-alt"></fa-icon> Configure Device</span>
        </button>
    </div>
</div>
