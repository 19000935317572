import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ConfigService } from '../services/config.service';

@Pipe({ name: 'nonIsoDatePipe' })
export class NonIsoDatePipe extends DatePipe implements PipeTransform {

	constructor(private readonly configService: ConfigService) { super(navigator.language); }

	public transform(value: any, format?: string, timezone?: string): any {

		if (value === null)
			return null;

		const date = new Date(value);

		return super.transform(date, format ?? this.configService.config.dateFormat, timezone, navigator.language);
	}
}
