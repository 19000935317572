<div class="install-client mat-elevation-z2" fxLayout="column" fxLayoutGap=".5rem">
	<div class="client-header" fxLayout fxLayoutAlign="space-between center" *ngIf="showWorkSummary">
		<div>Work Order Summary</div>
	</div>
	<div class="login-info" *ngIf="showWorkSummary">
		<form [formGroup]="workOrderForm" (ngSubmit)="updateWorkOrderDetails()">
			<mat-form-field appearance="standard">
				<mat-label>Summary</mat-label>
				<textarea
						type="text"
						matInput
						formControlName="summary" 
						[(ngModel)]="install.summary">
				</textarea>
			</mat-form-field>
			<br/>
			<mat-form-field appearance="standard">
				<mat-label>Description</mat-label>
				<textarea
						type="text"
						matInput
						formControlName="description" 
						[(ngModel)]="install.description">
				</textarea>
			</mat-form-field>
			<div class="button-container">
				<button mat-flat-button color="primary" type="submit">Save</button>
			</div>
		</form>
	</div>


	<div class="client-header" fxLayout fxLayoutAlign="space-between center">
		<div>Client Information</div>
		<fa-icon icon="user-friends"></fa-icon>
	</div>
	<div class="client-info">
		<h4>Type</h4>
		<div [ngSwitch]="install.type_id">
			<div *ngSwitchCase="workOrderType.INSTALL">
				Install
				<strong class="inline" *ngIf="installType"> - ({{installType}})</strong>
			</div>
			<div *ngSwitchCase="workOrderType.REPAIR">Repair</div>
			<div *ngSwitchCase="workOrderType.PREORDER">Pre-Order</div>
			<div *ngSwitchCase="workOrderType.RELOCATION">Relocation</div>
		</div>
		<div *ngIf="showServiceLevel">
			<h4>Service Level</h4>
			<div [ngSwitch]="install.extraInfo?.serviceLevel">
				<div *ngSwitchCase="serviceLevels.BASIC">Basic</div>
				<div *ngSwitchCase="serviceLevels.PREMIUM">Premium</div>
				<div *ngSwitchCase="serviceLevels.CRITICAL">Critical</div>
			</div>
		</div>
		<h4>Name</h4>
		<div>{{ install.first_name }} {{ install.last_name }}</div>
		<h4>Address</h4>
		<div>{{ install.address }}</div>
		<h4>Contact No</h4>
		<div>{{ install.mobile_number }}</div>
		<h4>Install Scheduled Date{{ !hideScheduledTime ? ('/Time') : ('') }}</h4>
		<div>{{ install.schedule_date | nonIsoDatePipe }}{{ !hideScheduledTime ? (' \- ' + (install.scheduled_date_time | date: 'shortTime')) : ('') }}</div>
		<h4>Reference</h4>
		<div>{{ install.reference }}</div>
		<div *ngIf="install.extraInfo?.providerName">
			<h4>Service Provider</h4>
			<div>{{ install.extraInfo.providerName }}</div>
		</div>
		<div *ngIf="showPaymentMedium">
			<h4>Valid Payment Medium</h4>
			<div>{{ hasValidPaymentMedium }}</div>
		</div>
		<h4>Work Order Comment</h4>
		<div>{{ install.last_comment }}</div>
		<div *ngIf="showHistory">
			<button mat-flat-button color="primary" (click)="navigateToHistory()" class="history-button"> Show
				History</button>
		</div>
		<button mat-mini-fab color="primary" (click)="phoneClient()" class="call-button button-position">
			<fa-icon icon="phone" size="lg"></fa-icon>
		</button>
	</div>
</div>
