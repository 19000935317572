import { HttpClient  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable  } from 'rxjs';
import { ParamMetaData, AuthType } from '../_shared/param-meta-data';
import { PurchaseApi } from './api';
import {IValidMedium} from './types';

@Injectable({ providedIn: 'root' })
export class PurchaseService {

  constructor(
    private readonly http: HttpClient,
  ) { }

  public validPaymentMedium(serviceId: string): Observable<IValidMedium> {
    return this.http.get<IValidMedium>(PurchaseApi.validMedium, {
      params: new ParamMetaData({ authToken: AuthType.USER })
        .set('serviceId', serviceId),
    });
  }
}

