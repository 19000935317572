import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IInstall } from '../../../../services/types';
import { NgForm } from '@angular/forms';
import { isNil } from 'lodash';

@Component({
	selector: 'app-pre-check',
	templateUrl: './pre-check.component.html',
})
export class PreCheckComponent {

	@Input() public readonly install: IInstall;
	@Input() public readonly customSurvey: boolean;
	@Input() public readonly solarInstallPreCheck: boolean;
	@ViewChild('form', {static: true}) private readonly form: NgForm;
	@Output() private readonly begin = new EventEmitter<void>();

	public readonly people: string[] = ['Self', 'Partner', 'Other'];
	public readonly locations: string[] = ['Living Room', 'Kitchen', 'Bedroom', 'Study', 'Other'];
	public preCheckItemOne: boolean;
	public preCheckItemTwo: boolean;
	public person: string;
	public otherPerson: string;
	public terminationBoxLocation: string;
	public otherTerminationBoxLocation: string;
	public placement: boolean;
	public newsletter: boolean;
	public marketing: boolean;

	public onSubmit(): void {
		this.begin.emit();
	}

	public get isValid(): boolean {
		if (!this.customSurvey)
			if (!this.solarInstallPreCheck)
				return this.form.valid && !isNil(this.preCheckItemOne) && !isNil(this.preCheckItemTwo);
			else
				return this.form.valid && this.preCheckItemOne && this.preCheckItemTwo;
		else
			return this.form.valid && !isNil(this.placement) && !isNil(this.newsletter) && !isNil(this.marketing);
	}
}
