<div class="install-client mat-elevation-z2" fxLayout="column" fxLayoutGap=".5rem">
	<div class="client-info">
		<div class="client-header" fxLayout fxLayoutAlign="space-between center">
			<div>PPPoE Information</div>
		</div>
		<h4>PPPoE Username</h4>
		<div>{{ service?.pppoe_username || 'None' }}&nbsp;<fa-icon icon="copy" size="lg" (click)="copyToClipboard(service.pppoe_username)"></fa-icon></div>
		<h4>PPPoE Password</h4>
		<div>{{ service?.pppoe_password || 'None' }}&nbsp;<fa-icon icon="copy" size="lg" (click)="copyToClipboard(service.pppoe_password)"></fa-icon></div>
		<br>

		<div class="client-header" fxLayout fxLayoutAlign="space-between center">
			<div>Wi-Fi Information</div>
		</div>
		<div>
			<h4>Wi-Fi Username</h4>
			<div>{{ service?.wifi_username || 'None' }}&nbsp;<fa-icon icon="copy" size="lg" (click)="copyToClipboard(service.wifi_username)"></fa-icon></div>
			<h4>Wi-Fi Password</h4>
			<div>{{ service?.wifi_password || 'None' }}&nbsp;<fa-icon icon="copy" size="lg" (click)="copyToClipboard(service.wifi_password)"></fa-icon></div>
		</div>
		<br>
		<button mat-flat-button color="primary" type="button" class="icon-inside" fxFlexAlign="center"
		        (click)="refreshPppoe()">
			<fa-icon icon="sync-alt"></fa-icon>
			<span>Refresh</span>
		</button>
	</div>
</div>
