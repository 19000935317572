import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { InstallService } from '../../../../services/install.service';
import { IInstall, IService } from '../../../../services/types';

@Component({
	selector: 'app-port-number',
	templateUrl: './port-number.component.html',
	styleUrls: ['./port-number.component.scss'],
})
export class PortNumberComponent implements OnInit {

	@Input()
	public readonly install: IInstall;
	public service: Partial<IService>;
	public portNumber: number;

	constructor(
		public installService: InstallService,
		private readonly toast: ToastrService,
	) {
	}

	public ngOnInit(): void {
		this.refreshPortNumber();
	}

	public refreshPortNumber(): void {
		this.installService.getService(this.install?.account_id).subscribe(service => {
			this.service = service;
			this.portNumber = service.port_number;
		});
	}

	public savePortNumber(): void {
		this.installService.updateAccount(this.install.account_id, { port_number: this.portNumber }).subscribe(() => {
			this.toast.success('Port Number Saved!');
			this.refreshPortNumber();
		});
	}
}