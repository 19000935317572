import { HttpParamsPrimitive, ILoaderMetaData, ITimeoutMetaData, ParamsWithMetaData, Primitive } from '@aex/ngx-toolbox';
import { IHttpErrorMetaData } from '@aex/ngx-toolbox/lib/toolbox/http-error.service';
import { HttpParams } from '@angular/common/http';

function hasMetaData(obj: {}): obj is ParamMetaData {
	return obj.hasOwnProperty('meta');
}

export function getMetaData(params: HttpParams): IParamMetaData | undefined {
	return hasMetaData(params) ? params.meta : undefined;
}

export class ParamMetaData extends ParamsWithMetaData<IParamMetaData> {

	public static fromParams(params: HttpParamsPrimitive): ParamMetaData {
		return new ParamMetaData(null, params);
	}

	// HttpParams is immutable - have to create a new object
	public set(param: string, value: Primitive): ParamMetaData {
		this.params = {...(this.params || {}), [param]: value};
		return new ParamMetaData(this.meta, this.params);
	}

}

export interface IParamMetaData extends ITimeoutMetaData, IHttpErrorMetaData, ILoaderMetaData {
	authToken?: AuthConfig

}

export type AuthConfig = AuthType | string;

export enum AuthType {
	USER, PROVIDER, NONE, EITHER,
}