import { NavigationService } from '@aex/ngx-toolbox';
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { defer } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { IInstall, IInstallUpdate } from 'src/app/services/types';
import { APP_ROUTES } from '../../../_shared/types';
import { InstallService } from '../../../services/install.service';
import {ConfigService} from '../../../services/config.service';

@Component({
	selector: 'app-install-hold',
	template: `
		<div class="install-action-container mat-elevation-z2">
			<form #form="ngForm" (ngSubmit)="form.valid && submitForm(form)" fxLayout="column" fxLayoutGap="1rem">
				<!-- On Hold Reason -->
				<mat-form-field>
						<mat-select id="reasonField" placeholder="On-Hold Reason" name="reason" ngModel="reason" #reasonField=ngModel required>
								<mat-option *ngFor="let reason of onHoldReasons" [value]="reason">{{ reason }}</mat-option>
						</mat-select>
						<mat-error *ngIf="reasonField.errors?.required">Reason is required</mat-error>
				</mat-form-field>

				<!-- Explanation -->
				<mat-form-field *ngIf="form.value.reason === 'Other'">
						<input id="otherField" matInput type="text" placeholder="Explanation" name="other" ngModel #otherField=ngModel required>
						<mat-error *ngIf="otherField.errors?.required">You must provide and explanation</mat-error>
				</mat-form-field>

				<!-- Serial Number -->
				<mat-form-field>
					<input id="serialField" matInput placeholder="Serial Number" name="serial" [(ngModel)]="serial" #serialField=ngModel required>
					<mat-error *ngIf="serialField.errors?.required">A serial number is required</mat-error>
				</mat-form-field>

				<button mat-flat-button color="primary" type="submit" class="icon-inside" fxFlexAlign="end">
						<fa-icon icon="pause-circle"></fa-icon>
						<span>Place {{ install.type_id | installType }} On Hold</span>
				</button>
			</form>
		</div>`,
	styleUrls: ['./install-action.scss'],
})
export class InstallHoldComponent {

	public readonly install: IInstall;
	public reason: string;
	public serial: string;

	getOnHoldReasons(): string[] {
		if (this.configService.config.customReasonDropDown?? false)
			return this.configService.config.typeTypeOnHoldList;
		else
			return [
				'No Electricity on Site',
				'No Live Fibre Reading at Meter',
				'Other',
			];
	}
	public readonly onHoldReasons = this.getOnHoldReasons();

	constructor(
		private readonly router: Router,
		private readonly installService: InstallService,
		private readonly toast: ToastrService,
		private readonly nav: NavigationService,
		private readonly configService: ConfigService,
	) {
		this.install = installService.currentInstall;
	}

	public submitForm(form: NgForm): void {
		// Update comment to add serial number
		let finalComments = form.value.reason;
		if (finalComments === 'Other')
			finalComments = `${finalComments} - ${form.value.other}`;

		const obj: Partial<IInstallUpdate> = {
			status_id: this.install.status_id,
			comments: finalComments,
			on_hold: true,
		};

		this.nav.startLoading();
		this.installService.updateInstall(this.install.id, this.install.type_id, obj).pipe(
			finalize(() => defer(() => this.nav.stopLoading())),
		).subscribe(() => {
			this.toast.success('Placed On Hold!');
			this.router.navigateByUrl(APP_ROUTES.installs.path).then();
		});
	}
}
