import { pathify, Primitive } from '@aex/ngx-toolbox';
import { endOfDay, isAfter, isBefore, isEqual, startOfDay } from 'date-fns';
import { environment } from '../../environments/environment';
import { IDateRange } from '../services/types';

export class FilenameUtils {

	public static getExt(filename: string): string {
		return filename.split('.').pop();
	}

	public static getTimestampFilename(filename: string): string {
		return `${ Math.floor(Date.now() / 1000).toString() }.${ FilenameUtils.getExt(filename) }`;
	}
}

export function serverUrl(...parts: Primitive[]): string {
	return (environment.serverUrl)
			? pathify(...[environment.serverUrl as Primitive].concat(parts))
			: pathify(...parts);
}

export function assetsServerUrl(...parts: Primitive[]): string {
	return (environment.assetsServerUrl)
			? pathify(...[environment.assetsServerUrl as Primitive].concat(parts))
			: pathify(...parts);
}

export function securityServiceUrl(...parts: Primitive[]): string {
	return (environment.securityServiceUrl)
			? pathify(...[environment.securityServiceUrl as Primitive].concat(parts))
			: pathify(...parts);
}

export function purchaseServiceUrl(...parts: Primitive[]): string {
	return (environment.purchaseServiceUrl)
		? pathify(...[environment.purchaseServiceUrl as Primitive].concat(parts))
		: pathify(...parts);
}

export function isDateInRange(date: Date, range: IDateRange, options?: IDateCompareOptions): boolean {
	const lStartDate = options?.useDayEdge && range.startDate ? startOfDay(range.startDate) : range.startDate;
	const lEndDate = options?.useDayEdge && range.endDate ? endOfDay(range.endDate) : range.endDate;
	return (!lStartDate || isAfter(date, lStartDate) || isEqual(date, lStartDate))
			&& (!lEndDate || isBefore(date, lEndDate) || isEqual(date, lEndDate));
}

export interface IDateCompareOptions {
	useDayEdge: boolean
}


