import { ICoordinates, NavigationService } from '@aex/ngx-toolbox';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isNil } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { APP_ROUTES } from '../_shared/types';

@Injectable({providedIn: 'root'})
export class MapService {

	private location: GeolocationCoordinates;

	constructor(
			private readonly toast: ToastrService,
			private readonly router: Router,
			private readonly navigationService: NavigationService,
	) {
		this.getLocation().subscribe();
	}

	public getLocation(loader?: string, force: boolean = true): Observable<GeolocationCoordinates> {
		if (force || !this.location) {
			this.navigationService.startLoading({loader});
			return new Observable<GeolocationCoordinates>(observer => {
				if (!(window.navigator && window.navigator.geolocation)) {
					observer.error('Unsupported Browser');
					this.toast.warning('This device or browser doesn\'t have access to your GPS, please enable it');
					this.router.navigateByUrl(APP_ROUTES.login.path).then();
				} else
					window.navigator.geolocation.getCurrentPosition(
							position => {
								this.location = position.coords;
								observer.next(this.location);
								observer.complete();
							},
							error => observer.error(error),
					);
			}).pipe(
					finalize(() => this.navigationService.stopLoading({loader})),
			);
		} else
			return of(this.location);
	}

	// Calculate Distance Between 2 points
	public calculateDistance(source: ICoordinates, destination: ICoordinates): number {
		// Calc the difference
		const dLat = degreesToRadians(source.latitude - destination.latitude);
		const dLon = degreesToRadians(source.longitude - destination.longitude);
		// Get radians for latitudes
		const lat1 = degreesToRadians(source.latitude);
		const lat2 = degreesToRadians(destination.latitude);
		// Hardcore maths stuff
		const a =
				Math.sin(dLat / 2) * Math.sin(dLat / 2) +
				Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
		const c = Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) * 2;
		const distance = EARTH_RADIUS * c;

		return Number(distance.toFixed(distance < 1 ? 1 : 2));
	}

	public isCloseEnough(destination: ICoordinates, radius: number, refreshLocation: boolean = false): Observable<boolean> {
		if (isNil(radius)) return of(true);
		const locationObs = this.location && !refreshLocation ? of(this.location) : this.getLocation();
		return locationObs.pipe(map(location => this.calculateDistance(location, destination) < radius));
	}

	// - Get Map Img Url based on Address
	public getMapUrl(address: string): string {
		const addressUrl = urlify(address);
		const params = new URLSearchParams({
			center: addressUrl,
			zoom: '15',
			size: '615x415',
			maptype: 'roadmap',
			markers: `color:red|${ addressUrl }`,
			key: environment.googleMapsKey,
		}).toString();
		return `https://maps.googleapis.com/maps/api/staticmap?${ params.toString() }`;
	}

}

// Earths radius in km
const EARTH_RADIUS = 6371;

function degreesToRadians(degrees: number): number {
	return (degrees * Math.PI) / 180;
}

function urlify(a: string): string {
	return a
			.toLowerCase()
			.replace(/[^a-z0-9]+/g, '-')
			.replace(/^-+|-+$/g, '-')
			.replace(/^-+|-+$/g, '');
}
