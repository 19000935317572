import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { APP_ROUTES } from '../../_shared/types';
import { ConfigService } from '../../services/config.service';
import { IInstall, InstallType, ServiceLevel } from '../../services/types';
import { NavigationService } from '@aex/ngx-toolbox';
import { StockService } from '../../services/stock.service';
import { InstallService } from 'src/app/services/install.service';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user.service';
import { PurchaseService } from '../../services/purchase.service';

@Component({
	selector: 'app-client-details',
	templateUrl: './client-details.component.html',
	styleUrls: ['./client-details.component.scss'],
})
export class ClientDetailsComponent implements OnInit {

	@Input() public install: IInstall;
	public workOrderType = InstallType;
	public serviceLevels = ServiceLevel;
	public installType: string;
	public showHistory: boolean;
	public showServiceLevel: boolean;
	public showWorkSummary: boolean;
	public workOrderForm: FormGroup;
	public nav: any;
	public loaderId: any;
	public woSummary: string;
	public woDescription: string;
	public hideScheduledTime: boolean;
	public useCustomDateFormat: boolean;
	public hasValidPaymentMedium: string;

	constructor(
			private readonly router: Router,
			private readonly configService: ConfigService,
			private readonly stockService: StockService,
			private readonly navigationService: NavigationService,
			private readonly formBuilder: FormBuilder,
			private readonly installService: InstallService,
			private readonly toast: ToastrService,
			private readonly userService: UserService,
			private readonly purchaseService: PurchaseService,
	) {
		this.showHistory = this.configService.config.showWorkOrderHistories;
		this.showServiceLevel = this.configService.config.showPremiumStatus;
		this.showWorkSummary = this.configService.config.showWorkSummary;
		this.hideScheduledTime = this.configService.config.hideScheduledTime;

	}

	public ngOnInit(): void {
		if (this.showPaymentMedium)
			this.purchaseService.validPaymentMedium(this.install.account_id)
				.subscribe(x => {
					if (x.has_valid_medium)
						this.hasValidPaymentMedium = 'Yes';
					else this.hasValidPaymentMedium = 'No';
				});

		if (this.install.type_id !== 13)
			this.stockService.getInstallTypeValues(this.install.id)
				.subscribe(stock => {
					if (stock?.attributes.length > 0) {
						const attributes = stock?.attributes as any[];
						this.installType = attributes.find(res => res.install_type)?.install_type;
					}
				});

		this.updateWorkOrderForm();
	}

	public phoneClient(): void {
		window.open(`tel:${ this.install.mobile_number }`, '_blank');
	}

	public navigateToHistory(): void {
		this.router.navigate([APP_ROUTES.install.path, this.install.type_id, this.install.id, 'workorder-history']).then();
	}

	public updateWorkOrderDetails(): void {
		// If no changes, then exit
		if (!this.anyWorkOrderChanges()) {
			this.toast.info('No changes made to Work Order Summary OR Description!');
			return;
		}

		this.navigationService.startLoading();

		// Build the comment in order for the WO History record auto-inserted
		const comment = this.buildWorkOrderComment();

		// Update the workorder Summary and Description
		this.installService.updateInstall(this.install.id, InstallType.ALL, {
				summary: this.install.summary,
				description: this.install.description,
				comments: comment,
				modified_by_id: this.userService.userId?.toString(),
			}).pipe(
				tap(
					() => {
						this.toast.success('Work Order successfully updated');
						this.updateWorkOrderForm();
					},
				),
			).subscribe();

		this.navigationService.stopLoading();
	}

	public get showPaymentMedium(): boolean{
		return ((this.configService.fnoName === 'openfiberusa' && this.install.type_id !== 13) || this.configService.fnoName === 'netninenine');
	}
	private anyWorkOrderChanges(): boolean {
		return (this.install.summary !== this.woSummary) || (this.install.description !== this.woDescription);
	}

	private buildWorkOrderComment(): string {
		this.install.summary = this.install.summary.trim();
		this.install.description = this.install.description.trim();

		let comment = '';
		const woSummaryChanged = this.install.summary !== this.woSummary;
		const woDescriptionChanged = this.install.description !== this.woDescription;
		// Both changed?
		if (woSummaryChanged && woDescriptionChanged)
			comment = `Summary => ${this.install.summary} || Description => ${this.install.description}`;
		// Summary changed?
		else if (woSummaryChanged)
			comment = `Summary => ${this.install.summary}`;
		// Description changed?
		else if (woDescriptionChanged)
			comment = `Description => ${this.install.description}`;
		return comment;
	}

	private updateWorkOrderForm(): void {
		this.woSummary = this.install.summary ?? '';
		this.install.summary = this.woSummary;
		this.woDescription = this.install.description ?? '';
		this.install.description = this.woDescription;

		this.workOrderForm = this.formBuilder.group({
			summary: [this.woSummary],
			description: [this.woDescription],
		});
	}

}
