import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { addDays, addMonths, addYears, format, getDate, getDaysInMonth, getMonth, getYear, parse, setDay, setMonth, toDate } from 'date-fns';
import { enGB, enUS } from 'date-fns/locale';

const WEEK_STARTS_ON = navigator.language === 'en-US' ? 0 : 1;
export const MATERIAL_DATE_FORMAT = {
	parse: {
		dateInput: navigator.language === 'en-US' ? 'MMM dd, yyyy' : 'dd MMM yyyy',
	},
	display: {
		dateInput: navigator.language === 'en-US' ? 'MMM dd, yyyy' : 'dd MMM yyyy',
		monthYearLabel: 'MMM yyyy',
		dateA11yLabel: 'd MMMM yyyy',
		monthYearA11yLabel: 'MMMM yyyy',
	},
};


@Injectable()
export class DateFnsDateAdapter extends DateAdapter<Date> {

	public addCalendarDays(date: Date, days: number): Date {
		return addDays(date, days);
	}

	public addCalendarMonths(date: Date, months: number): Date {
		return addMonths(date, months);
	}

	public addCalendarYears(date: Date, years: number): Date {
		return addYears(date, years);
	}

	public clone(date: Date): Date {
		return toDate(date);
	}

	public createDate(year: number, month: number, date: number): Date {
		return new Date(year, month, date);
	}

	public format(date: Date, displayFormat: string): string {
		switch (navigator.language) {
		case 'en-US':
			return format(date, displayFormat, { locale: enUS });
		case 'en-GB':
			return format(date, displayFormat, { locale: enGB });
		default:
			return format(date, displayFormat, { locale: enGB });
		}
	}

	public getDate(date: Date): number {
		return getDate(date);
	}

	public getDateNames(): string[] {
		return range(1, 31).map(day => String(day));
	}

	public getDayOfWeek(date: Date): number {
		return parseInt(format(date, 'i'), 10);
	}

	public getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
		const map = {
			long: 'EEEE',
			short: 'E..EEE',
			narrow: 'EEEEE',
		};

		const formatStr = map[style];
		const date = new Date();

		switch (navigator.language) {
			case 'en-US':
				return range(0, 6).map(month => format(setDay(date, month), formatStr, { locale: enUS }));
			case 'en-GB':
				return range(0, 6).map(month => format(setDay(date, month), formatStr, { locale: enGB }));
			default:
				return range(0, 6).map(month => format(setDay(date, month), formatStr, { locale: enGB }));
		}
	}

	public getFirstDayOfWeek(): number {
		return WEEK_STARTS_ON;
	}

	public getMonth(date: Date): number {
		return getMonth(date);
	}

	public getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
		const map = {
			long: 'LLLL',
			short: 'LLL',
			narrow: 'LLLLL',
		};

		const formatStr = map[style];
		const date = new Date();

		switch (navigator.language) {
			case 'en-US':
				return range(0, 11).map(month => format(setMonth(date, month), formatStr, { locale: enUS }));
			case 'en-GB':
				return range(0, 11).map(month => format(setMonth(date, month), formatStr, { locale: enGB }));
			default:
				return range(0, 11).map(month => format(setMonth(date, month), formatStr, { locale: enGB }));
		}
	}

	public getNumDaysInMonth(date: Date): number {
		return getDaysInMonth(date);
	}

	public getYear(date: Date): number {
		return getYear(date);
	}

	public getYearName(date: Date): string {
		switch (navigator.language) {
			case 'en-US':
				return format(date, 'yyyy', { locale: enUS });
			case 'en-GB':
				return format(date, 'yyyy', { locale: enGB });
			default:
				return format(date, 'yyyy', { locale: enGB });
		}
	}

	public invalid(): Date {
		return new Date(NaN);
	}

	public isDateInstance(obj: any): boolean {
		return obj instanceof Date;
	}

	public isValid(date: Date): boolean {
		return date instanceof Date && !isNaN(date.getTime());
	}

	public parse(value: any, parseFormat: any): Date | null {
		switch (navigator.language) {
			case 'en-US':
				return parse(value, parseFormat, new Date(), { locale: enUS });
			case 'en-GB':
				return parse(value, parseFormat, new Date(), { locale: enGB });
			default:
				return parse(value, parseFormat, new Date(), { locale: enGB });
		}
	}

	public toIso8601(date: Date): string {
		return date.toISOString();
	}

	public today(): Date {
		return new Date();
	}
}

function range(start: number, end: number): number[] {
	const arr: number[] = [];
	for (let i = start; i <= end; i++)
		arr.push(i);
	return arr;
}