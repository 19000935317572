<div class="image-upload-container mat-elevation-z2 loader-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1rem">
	<ngx-ui-loader [loaderId]="loadingFilesLoaderId" [bgsPosition]="LoaderPosition.bottomCenter"></ngx-ui-loader>

	<app-image-upload [imageTypes]="imageTypes" [namespace]="namespace"></app-image-upload>

	<div *ngIf="imagesUploaded.length" fxLayout="column" fxLayoutGap=".5rem" class="uploaded-images">
		<h3>Uploaded Files</h3>
		<div *ngFor="let image of imagesUploaded" class="uploaded-image">
			<hr>
			<div fxLayout fxLayoutGap="2rem" fxLayoutAlign="space-between center" class="w-100">
				<fa-icon icon="image" class="uploaded-image-icon"></fa-icon>
				<div>
					<h4>{{image.type}}</h4>
					<div>Filename: {{image.filename}}</div>
					<div>Uploaded: {{image.updated_at | date}}</div>
				</div>
				<div fxLayout="column" fxLayoutGap=".5rem" fxLayoutAlign="center center">
					<fa-icon icon="cloud-download-alt" class="action-icon" (click)="downloadFile(image)"></fa-icon>
				</div>
			</div>
		</div>
	</div>
</div>
