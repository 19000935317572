<div class="install-summary mat-elevation-z2">
	<mat-accordion hideToggle="true">
		<mat-expansion-panel [expanded]="onlyOne">
			<mat-expansion-panel-header title="Click for more details" class="install-reference">
				<div fxFlex="left" class="install-reference">{{ install.schedule_date | nonIsoDatePipe }}</div>
				<div fxFlex="left" class="install-reference" *ngIf="!showAddress">{{ install.reference }}</div>
				<div fxFlex="left" class="install-reference" *ngIf="showAddress">{{ install.address }}</div>
				<div fxFlex="left" class="install-reference" *ngIf="showWorkSummary">{{ install.summary }}</div>
			</mat-expansion-panel-header>

			<ng-template matExpansionPanelContent>
				<div fxLayoutGap=".2rem">
					<div class="install-summary-info" fxLayout fxLayoutAlign="space-between">
						<div *ngIf="!hideScheduledTime">{{ install.scheduled_date_time | date: 'shortTime' }}</div>
						<div>{{ install.distance | installDistance}}</div>
					</div>

					<div class="install-reference" fxLayout *ngIf="showWorkSummary"><strong class="description-text">Description: </strong>
						<label class="multi-line-text">{{ install.description }}</label>
					</div>
					<div class="install-summary-map">
						<img [src]="install.map_url" alt=""/>
						<div class="status-badge" [ngClass]="install.status_class">
							{{ install.status }}
						</div>
					</div>
					<div class="install-summary-buttons" fxLayout="row" fxLayoutGap="1rem" fxLayoutAlign="space-between">
					<span>
						<button mat-flat-button color="primary" (click)="show()"> Show {{ install.type_id | installType }}</button>
					</span>

						<button mat-flat-button color="primary" (click)="navigate()"> navigate here</button>
					</div>
				</div>
			</ng-template>
		</mat-expansion-panel>
	</mat-accordion>
</div>
