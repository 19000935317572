import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InstallService } from '../../../services/install.service';
import { IInstall, IWorkOrderHistory } from '../../../services/types';
import { ConfigService } from '../../../services/config.service';

export interface IDialogData {
	workOrder: IInstall;
}

@Component({
	selector: 'app-install-history-detailed',
	templateUrl: './install-history-detailed.component.html',
	styleUrls: ['./install-history-detailed.component.scss'],
})
export class InstallHistoryDetailedComponent {

	public workOrder: IInstall;
	public workOrderHistory: IWorkOrderHistory[] = [];
	public hideLastComment: boolean;

	constructor(
		private readonly installService: InstallService,
		public dialogRef: MatDialogRef<InstallHistoryDetailedComponent>,
		private readonly configService: ConfigService,
		@Inject(MAT_DIALOG_DATA) public data: IDialogData,
	) {
		this.workOrder = data.workOrder;
		this.getWorkOrderHistory();
		this.hideLastComment = !this.configService.config.hideLastComment;
	}

	public getWorkOrderHistory(): void {
		this.installService.getWorkOrderHistory(this.workOrder.id).subscribe((w: any) => this.workOrderHistory = w.list);
	}

}
