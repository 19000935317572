<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<input type="file" #fileInput id="cameraInput" class="hidden-file-input" capture="camera" accept="file_extension" name="cameraInput" onclick="this.value = null" (change)="onFileSelected($event)" multiple/>

<!-- Image upload selector -->
<div fxLayout fxLayoutGap="1rem">
	<mat-form-field fxFlex="1 1 15em">
		<mat-select placeholder="Upload Type" [(value)]="selectedType" (selectionChange)="chooseFile()">
			<mat-option *ngFor="let type of imageTypes" [value]="type">{{type}}</mat-option>
		</mat-select>
	</mat-form-field>

	<button mat-mini-fab color="primary" type="button" [disabled]="!selectedType" (click)="chooseFile()">
		<fa-icon icon="camera" size="lg"></fa-icon>
	</button>
</div>

<div *ngIf="filesToUpload.length" fxLayout="column" fxLayoutGap="1rem">
	<div *ngFor="let file of filesToUpload; let last = last" class="file-to-upload">
		<div fxLayout fxLayoutGap="2rem" fxLayoutAlign="space-between center">
			<div>
				<h4>{{file.type}}</h4>
				<div *ngIf="file.status">Status: <span class="image-status" [ngClass]="{success: file.status === fileUploadStatus.UPLOADED, danger: file.status === fileUploadStatus.ERROR}">{{file.status}}</span></div>
				<div *ngIf="file.size">Size: {{file.size | filesize}}</div>
			</div>
			<fa-icon icon="trash-alt" class="action-icon" *ngIf="!progress.has(file.guid)" (click)="removeFile(file)"></fa-icon>
		</div>
		<mat-progress-bar *ngIf="progress.has(file.guid)" mode="determinate" [value]="progress.get(file.guid) | async"></mat-progress-bar>
		<hr *ngIf="!last">
	</div>
	<button mat-flat-button color="primary" type="button" class="icon-inside" fxFlexAlign="center" (click)="uploadImages()" *ngIf="filesToUpload.length" [disabled]="!!progress.length">
		<fa-icon icon="cloud-upload-alt"></fa-icon>
		<span>Upload Files</span>
	</button>
</div>

