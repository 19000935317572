import { NavigationService } from '@aex/ngx-toolbox';
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { defer } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { ConfigService } from '../services/config.service';
import { APP_ROUTES } from '../_shared/types';

@Component({
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

	constructor(
			private readonly toast: ToastrService,
			private readonly router: Router,
			private readonly authService: AuthService,
			private readonly navigationService: NavigationService,
			private readonly configService: ConfigService,
			route: ActivatedRoute,
	) {
		route.params.subscribe(({returnUrl}) => this.authService.returnUrl = returnUrl);
	}

	public handleLogin(form: NgForm): void {
		this.navigationService.startLoading();
		this.authService.login(form.value.username, form.value.password).pipe(
				finalize(() => defer(() => this.navigationService.stopLoading())),
		).subscribe(() => {
			this.toast.info('You\'ve been logged in');
			this.router.navigateByUrl(APP_ROUTES.installs.path).then();
		});
	}

	public get allowMicrosoftLogin(): boolean {
		return this.configService.config.allowMicrosoftLogin;
	}

	public loginWithMicrosoft(): void {
		this.navigationService.startLoading();
		this.authService.loginWithMicrosoft().subscribe();
	}
}
