<!-- Install - Reschedule Form-->
<div class="install-action-container mat-elevation-z2">
	<form #form="ngForm" (ngSubmit)="form.valid && submitForm(form)" fxLayout="column" fxLayoutGap="1rem">
		<!-- Date Picker-->
		<mat-form-field>
			<input id="dateField" matInput placeholder="New Date" [matDatepicker]="datePicker" name="date" [(ngModel)]="date" (click)="datePicker.open()" [min]="minDate" [max]="maxDate" #dateField=ngModel required>
			<mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
			<mat-datepicker #datePicker></mat-datepicker>
			<mat-error *ngIf="dateField.errors?.required">A new date is required</mat-error>
		</mat-form-field>
		<!-- Time Select -->
		<mat-form-field>
			<input id="timeField" matInput placeholder="New Time" [ngxTimepicker]="timePicker" [format]="24" min="09:00" max="16:00" name="time" [(ngModel)]="time" #timeField=ngModel required>
			<ngx-material-timepicker-toggle [for]="timePicker" matSuffix></ngx-material-timepicker-toggle>
			<ngx-material-timepicker #timePicker [minutesGap]="10" [theme]="timePickerTheme"></ngx-material-timepicker>
			<mat-error *ngIf="timeField.errors?.required">A new time is required</mat-error>
		</mat-form-field>
		<!-- Reason Input -->
		<mat-form-field *ngIf="displayReasonDropDown">
			<mat-label>Reason</mat-label>
			<mat-select placeholder="Select a reason" name="reasonFieldDropdown" [(ngModel)]="selectedReason" #reasonFieldDropdown="ngModel" required>
				<mat-option id="reasonFieldDropdown" *ngFor="let reasonOption of rescheduleReasons" [value]="reasonOption">{{ reasonOption }}</mat-option>
			</mat-select>
			<mat-error *ngIf="reasonFieldDropdown.errors?.required">A reason is required</mat-error>
		</mat-form-field>

		<mat-form-field *ngIf="!displayReasonDropDown || selectedReason === 'Customer refused'">
			<input id="reasonField" matInput placeholder="Reason" name="reason" [(ngModel)]="reason" #reasonField=ngModel required>
			<mat-error *ngIf="reasonField.errors?.required">A reason is required</mat-error>
		</mat-form-field>
		<button mat-flat-button color="primary" type="submit" class="icon-inside" fxFlexAlign="end">
			<fa-icon [icon]="['far', 'clock']"></fa-icon>
			<span>Reschedule {{ install.type_id | installType }}</span>
		</button>
	</form>
</div>
