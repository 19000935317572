/* eslint-disable complexity */
import { getFnoName, mapToNull, NavigationService, StorageService } from '@aex/ngx-toolbox';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { defer, merge } from 'lodash';
import { Observable } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ConfigApi } from './api';
import {
	IEnvFile,
	IFnoConfiguration,
	Dictionary,
	InstallType,
	InstallStatusId,
	FaultStatusId,
	RepairStatusId,
	PreOrderStatusId,
	RelocationStatusId,
	PremiumFaultStatusId,
	PremiumRepairStatusId,
	IKeyValuePair, NidInstallStatusId,
} from './types';

@Injectable()
export class ConfigService {
	private _fnoName: string;

	constructor(
		private readonly http: HttpClient,
		private readonly navigationService: NavigationService,
		private readonly storageService: StorageService,
	) { }

	private _config: IFnoConfiguration;
	public get config(): IFnoConfiguration { return this._config; }

	public beginStatus(installType: InstallType): Guid {
		switch (installType) {
			case InstallType.INSTALL:
				return this._config?.installStatuses?.begin ?? Guid.parse(InstallStatusId.INSTALLATION_IN_PROGRESS);
			case InstallType.FAULT:
				return this._config?.faultStatuses?.begin ?? Guid.parse(FaultStatusId.PENDING_RESOLUTION);
			case InstallType.REPAIR:
				return this._config?.repairStatuses?.begin ?? Guid.parse(RepairStatusId.REPAIR_IN_PROGRESS);
			case InstallType.PREORDER:
				return this._config?.preOrderStatuses?.begin ?? Guid.parse(PreOrderStatusId.INSTALLATION_IN_PROGRESS);
			case InstallType.RELOCATION:
				return this._config?.relocationStatuses?.begin ?? Guid.parse(RelocationStatusId.RELOCATION_IN_PROGRESS);
			case InstallType.PREMIUM_SUPPORT_FAULT:
				return this._config?.premiumFaultStatuses?.begin ?? Guid.parse(PremiumFaultStatusId.PENDING_RESOLUTION);
			case InstallType.PREMIUM_SUPPORT_REPAIR:
				return this._config?.premiumRepairStatuses?.begin ?? Guid.parse(PremiumRepairStatusId.MAINTENANCE_IN_PROGRESS);
			case InstallType.NIDINSTALL:
				return this._config?.nidInstallStatuses?.begin ?? Guid.parse(NidInstallStatusId.CIVIL_DROP);
			default:
				throw new Error('Cannot retrieve begin status for invalid InstallType');
		}
	}

	public finishStatus(installType: InstallType): Dictionary<Guid> {
		switch (installType) {
		case InstallType.INSTALL:
				return this._config?.installStatuses?.finish ?? new Dictionary<Guid>([{ key: 'PENDING_ONT_DISCOVERY', value: Guid.parse(InstallStatusId.PENDING_ONT_DISCOVERY) }]);
			case InstallType.FAULT:
				return this._config?.faultStatuses?.finish ?? new Dictionary<Guid>([{ key: 'TESTING', value: Guid.parse(FaultStatusId.TESTING) }]);
			case InstallType.REPAIR:
				return this._config?.repairStatuses?.finish ?? new Dictionary<Guid>([{ key: 'TESTING', value: Guid.parse(RepairStatusId.TESTING) }]);
			case InstallType.PREORDER:
				return this._config?.preOrderStatuses?.finish ?? new Dictionary<Guid>(
					[{ key: 'INSTALLATION_COMPLETE', value: Guid.parse(PreOrderStatusId.INSTALLATION_COMPLETE) }],
				);
			case InstallType.RELOCATION:
				return this._config?.relocationStatuses?.finish ?? new Dictionary<Guid>(
					[{ key: 'PENDING_ONT_DISCOVERY', value: Guid.parse(RelocationStatusId.PENDING_ONT_DISCOVERY) }],
				);
			case InstallType.PREMIUM_SUPPORT_FAULT:
				return this._config?.premiumFaultStatuses?.finish ?? new Dictionary<Guid>([{ key: 'TESTING', value: Guid.parse(PremiumFaultStatusId.TESTING) }]);
			case InstallType.PREMIUM_SUPPORT_REPAIR:
				return this._config?.premiumRepairStatuses?.finish ?? new Dictionary<Guid>([{ key: 'TESTING', value: Guid.parse(PremiumRepairStatusId.TESTING) }]);
			case InstallType.NIDINSTALL:
				return this._config?.nidInstallStatuses?.finish ?? new Dictionary<Guid>(
					[{ key: 'NID_INSTALLATION_COMPLETE', value: Guid.parse(NidInstallStatusId.NID_INSTALLATION_COMPLETE) }]);
			default:
				throw new Error('Cannot retrieve finish status for invalid InstallType');
		}
	}

	public cancelStatus(installType: InstallType): Guid {
		switch (installType) {
			case InstallType.INSTALL:
				return this._config?.installStatuses?.cancel ?? Guid.parse(InstallStatusId.INSTALLATION_SCHEDULE_PENDING);
			case InstallType.FAULT:
				return this._config?.faultStatuses?.cancel ?? Guid.parse(FaultStatusId.PENDING_RESOLUTION);
			case InstallType.REPAIR:
				return this._config?.repairStatuses?.cancel ?? Guid.parse(RepairStatusId.SCHEDULE_PENDING);
			case InstallType.PREORDER:
				return this._config?.preOrderStatuses?.cancel ?? Guid.parse(PreOrderStatusId.INSTALLATION_SCHEDULE_PENDING);
			case InstallType.RELOCATION:
				return this._config?.relocationStatuses?.cancel ?? Guid.parse(RelocationStatusId.PENDING_RELOCATION);
			case InstallType.PREMIUM_SUPPORT_FAULT:
				return this._config?.premiumFaultStatuses?.cancel ?? Guid.parse(PremiumFaultStatusId.PENDING_RESOLUTION);
			case InstallType.PREMIUM_SUPPORT_REPAIR:
				return this._config?.premiumRepairStatuses?.cancel ?? Guid.parse(PremiumRepairStatusId.SCHEDULE_PENDING);
			case InstallType.NIDINSTALL:
				return this._config?.nidInstallStatuses?.cancel ?? Guid.parse(NidInstallStatusId.CIVIL_DROP);
			default:
				throw new Error('Cannot retrieve cancel status for invalid InstallType');
		}
	}

	public mapToKeyValuePair<T>(dictionary: Dictionary<T>): IKeyValuePair<string, T>[] {
		const values: IKeyValuePair<string, T>[] = [];

		for (const value of Object.keys(dictionary))
			values.push({ key: value, value: dictionary[value] });

		return values;
	}

	public get fnoName(): string {
		return this._fnoName;
	}

	public store(key: string, value: string): void {
		this.storageService.store(key, value, this._config?.encryptLocalStore);
	}

	public retrieve(key: string): string {
		return this.storageService.retrieve(key, this._config?.encryptLocalStore);
	}

	public get googleAnalytics(): string {
		return this._config?.googleAnalyticsKey;
	}
	public get hasGoogleAnalytics4(): boolean {
		return this._config?.hasGoogleAnalytics4 ?? false;
	}
	public get googleAnalytics4Key(): string {
		return this._config?.googleAnalytics4Key ?? '';
	}
	public get imageTypes(): string[] {
		return this._config?.imageTypeList;
	}
	public loadAppConfig(): Observable<void> {
		// Explicit call to start and deferred stop loading ensures the app init is bundled with whatever other startup ops
		this.navigationService.startLoading();

		return this.http.get<IEnvFile>(ConfigApi.dynamicEnvironment).pipe(
			switchMap(envConfig => {
				merge(environment, envConfig.environments[getConfigName()], environment.override);
				this._fnoName = getFnoName(environment, envConfig.operatorResolution);
				return this.http.get<IFnoConfiguration>(ConfigApi.config(this._fnoName));
			}),
			catchError(() => this.http.get<IFnoConfiguration>(ConfigApi.config(DEFAULT_CONFIG))),
			tap(config => this._config = config),
			mapToNull(),
			finalize(() => defer(() => this.navigationService.stopLoading())),
		);
	}

}

function getConfigName(): string {
	return environment.name;
}

const DEFAULT_CONFIG = 'default';

// End of abstraction
