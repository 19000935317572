<form #form="ngForm" class="install-pre-check" fxLayout="column" fxLayoutGap="1rem" (ngSubmit)="isValid && onSubmit()">
	<mat-card-title>Pre-{{ install.type_id | installType }} Checklist</mat-card-title>

	<div *ngIf="!customSurvey, else custom">
		<mat-form-field>
			<mat-select id="person" name="person" placeholder="Person Present" [(ngModel)]="person" required>
				<mat-option *ngFor="let person of people" [value]="person">
					{{ person }}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field *ngIf="person === people[2]">
			<input matInput type="text" placeholder="Other Person" [(ngModel)]="otherPerson" name="other" required>
		</mat-form-field>

		<section fxLayout fxLayoutGap="1rem" fxLayoutAlign="space-between center">
			<label class="check-label">
				{{!solarInstallPreCheck ? 'DStv Working:': 'Site Checklist Received'}}
			</label>
			<mat-button-toggle-group name="dstvWorking" [(ngModel)]="preCheckItemOne">
				<mat-button-toggle [value]="true">Yes</mat-button-toggle>
				<mat-button-toggle [value]="false">No</mat-button-toggle>
			</mat-button-toggle-group>
		</section>

		<section fxLayout fxLayoutGap="1rem" fxLayoutAlign="space-between center">
			<label class="check-label">
				{{!solarInstallPreCheck ? 'Wi-Fi Working:': 'Wi-Fi at Inverter Location'}}
			</label>
			<mat-button-toggle-group name="wirelessWorking" [(ngModel)]="preCheckItemTwo">
				<mat-button-toggle [value]="true">Yes</mat-button-toggle>
				<mat-button-toggle [value]="false">No</mat-button-toggle>
			</mat-button-toggle-group>
		</section>
	</div>

	<ng-template #custom>
		<mat-form-field>
			<mat-select [(ngModel)]="terminationBoxLocation" name="location" placeholder="Location of Termination Box" required>
				<mat-option *ngFor="let location of locations" [value]="location">{{ location }}</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field *ngIf="terminationBoxLocation === locations[4]">
			<input matInput type="text" [(ngModel)]="otherTerminationBoxLocation" name="other" placeholder="Other Location" required>
		</mat-form-field>

		<section fxLayout fxLayoutGap="1rem" fxLayoutAlign="space-between center">
			<label class="check-label">Installation options regarding route and ONT placement discussed and agreed?</label>
			<mat-button-toggle-group name="placement" [(ngModel)]="placement">
				<mat-button-toggle [value]="true">Yes</mat-button-toggle>
				<mat-button-toggle [value]="false">No</mat-button-toggle>
			</mat-button-toggle-group>
		</section>

		<section fxLayout fxLayoutGap="1rem" fxLayoutAlign="space-between center">
			<label class="check-label">Would you like to Sign Up for Newsletters and Communication from Zoom Fibre:</label>
			<mat-button-toggle-group name="newsletter" [(ngModel)]="newsletter">
				<mat-button-toggle [value]="true">Yes</mat-button-toggle>
				<mat-button-toggle [value]="false">No</mat-button-toggle>
			</mat-button-toggle-group>
		</section>

		<section fxLayout fxLayoutGap="1rem" fxLayoutAlign="space-between center">
			<label class="check-label">Would you like to receive marketing Material from Zoom Fibre:</label>
			<mat-button-toggle-group name="marketing" [(ngModel)]="marketing">
				<mat-button-toggle [value]="true">Yes</mat-button-toggle>
				<mat-button-toggle [value]="false">No</mat-button-toggle>
			</mat-button-toggle-group>
		</section>
	</ng-template>



	<button mat-flat-button color="primary" type="submit" class="icon-inside" fxFlexAlign="center" [disabled]="!isValid">
		<fa-icon icon="stopwatch"></fa-icon>
		<span>Begin {{ install.type_id | installType }}</span>
	</button>
</form>
