import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { fromPairs } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from 'src/app/services/config.service';
import { StockService } from '../../../../services/stock.service';
import { IInstall, IStock } from '../../../../services/types';

@Component({
	selector: 'app-assets',
	templateUrl: './assets.component.html',
	styleUrls: ['./assets.component.scss'],
})
export class AssetsComponent implements OnInit {

	@Input() public readonly install: IInstall;
	@Input() public readonly assetRequirements: IStock[];
	@Input() public readonly assetType: string;
	@Input() public readonly dropdown: string[];
	@ViewChild('form', {static: true}) private readonly form: NgForm;

	public requirements: IStock[];

	public get disableServiceAssetsUpdate(): boolean {
		return this.configService.config.disableServiceAssetsUpdate;
	}

	constructor(
		private readonly stockService: StockService,
		private readonly toast: ToastrService,
		private readonly configService: ConfigService,
	) { }

	public ngOnInit(): void {
		this.requirements = this.assetRequirements.filter((s: any) => s.type !== null);
		if (this.assetType === 'Work Order Assets')
			this.stockService.getStockValues(this.install.id)
					.subscribe(stock => this.setFormValues(stock?.attributes[0]));
		else
			this.stockService.getAssetValues(this.install.account_id)
					.subscribe(assets => {
						this.setFormValues(assets?.attributes[0]);
					});
	}

	private setFormValues(requirement: any): void {
		if (!requirement) return;
		const formValue = fromPairs(this.requirements.map(attribute => {

			let assetRequirementAttribute = requirement.attributes.find(
				(attr: any) => Object.keys(attr).toString() === `${attribute.description}:${attribute.type}`,
			);
			if (!assetRequirementAttribute)
				assetRequirementAttribute = requirement.attributes.find((attr: any) => Object.keys(attr).toString() === attribute.description);

			let value = '';

			if (assetRequirementAttribute)
				value = Object.values(assetRequirementAttribute).toString();

			return [
				attribute.description,
				value,
			];
		}));
		this.form.setValue(formValue);
	}

	public onSubmit(form: NgForm): void {
		const attributes = form.value;
		const attributeArray: any[] = [];
		Object.keys(form.value).forEach(index => {
			const attribute = this.requirements.find((attr: any) => attr.description === index);
			const indexName = attribute.type !== 'text' ? `${index}:${attribute.type}` : index;
			attributeArray.push({[indexName]:attributes[index]});
		});

		if (attributeArray.length)
			if (this.assetType === 'Work Order Assets')
				this.stockService.saveStock(this.install.id, attributeArray)
						.subscribe(() => this.assetsSaved());
			else
				this.stockService.saveAssets(this.install.account_id, attributeArray)
						.subscribe(() => this.assetsSaved());
	}
	private assetsSaved(): void {
		this.form.form.markAsPristine();
		this.toast.success(`${this.assetType} updated successfully`);
	}

	public isTextArea(item: IStock): boolean {
		return item.type === 'textarea';
	}

	public get errors(): string[] {
		const result = [];
		if (this.form.dirty)
			result.push(`${this.assetType} has not been saved`);
		if (!this.form.valid)
			result.push(`${this.assetType} capture is not valid`);
		return result;
	}

}
