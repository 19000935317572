import { NavigationService } from '@aex/ngx-toolbox';
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { addYears, format as formatDate } from 'date-fns';
import { defer } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { APP_ROUTES, MATERIAL_TIME_PICKER_THEME } from '../../../_shared/types';
import { InstallService } from '../../../services/install.service';
import { IInstall, IInstallUpdate } from '../../../services/types';
import {ConfigService} from '../../../services/config.service';

@Component({
	templateUrl: './install-reschedule.component.html',
	styleUrls: ['./install-action.scss'],
})
export class InstallRescheduleComponent {

	public readonly timePickerTheme = MATERIAL_TIME_PICKER_THEME;
	public readonly minDate = new Date();
	public readonly maxDate = addYears(new Date(), 2);
	public readonly install: IInstall;

	public date: Date;
	public time: string;
	public reason: string;
	selectedReason: string;

	public displayReasonDropDown = this.configService.config.displayReasonDropDown?? false;

	constructor(
			private readonly installService: InstallService,
			private readonly router: Router,
			private readonly toast: ToastrService,
			private readonly nav: NavigationService,
			private readonly configService: ConfigService,
	) {
		this.install = this.installService.currentInstall;
	}
	getRescheduleOptions(): string[] {
			return this.configService.config.typeRescheduledList;
	}
	public readonly rescheduleReasons = this.getRescheduleOptions();

	public submitForm(form: NgForm): void {
		const obj: Partial<IInstallUpdate> = {
			status_id: this.install.status_id,
			comments: form.value.reason,
			schedule_date: formatDate(form.value.date, 'yyyy-MM-dd'),
			schedule_time: new Date(`${formatDate(form.value.date, 'yyyy-MM-dd')} ${ form.value.time }:00`).toISOString(),
		};

		if (this.displayReasonDropDown)
			obj.comments = `${this.selectedReason} : ${obj.comments}`;

		this.nav.startLoading();
		this.installService.updateInstall(this.install.id, this.install.type_id, obj).pipe(
				finalize(() => defer(() => this.nav.stopLoading())),
		).subscribe(() => {
			this.toast.success('Reschedule Done!');
			this.router.navigateByUrl(APP_ROUTES.installs.path).then();
		});
	}

}