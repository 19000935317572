import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IDiscovery, IFnoConfiguration, IInstall, InstallType } from '../../../services/types';
import { ServiceStatusService } from '../../../services/service-status.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-install-status',
	templateUrl: './install-status.component.html',
	styleUrls: ['./install-status.component.scss'],
})
export class InstallStatusComponent {

	@Input() public readonly install: IInstall;
	public workOrderType = InstallType;
	public discovery: IDiscovery;
	public fsanSaved: boolean;
	public readonly conf: IFnoConfiguration;
	public installDetails: IInstall;

	constructor(
		private readonly toast: ToastrService,
		private readonly serviceStatus: ServiceStatusService,
		public readonly route: ActivatedRoute,
	) {

		this.discovery = {
			hub_facility: '',
			hub_number: '',
			hub_switch_blade: 0,
			hub_switch_port: 0,
			hub_switch_model: '',
			hub_switch_ip_address: '',
			hub_rx_levels: '',
			hub_tx_levels: '',
			gateway_rx_levels: '',
			gateway_tx_levels: '',
			ont_model: '',
			ont_ip_address: '',
		};

		route.data.subscribe(({workOrder}) => {
			this.installDetails = workOrder;
			if (this.installDetails.extraInfo.serialNumber)
				this.serviceStatus.getDeviceDiscoveryFields(this.installDetails.extraInfo.serialNumber).subscribe(res => {
					this.discovery.hub_facility = res.description;
					this.discovery.hub_number = res.parent_information.identifier.charAt(res.parent_information.identifier.length -1);
					this.discovery.hub_switch_blade = res.identifier;
					this.discovery.hub_switch_port = res.identifier;
					this.discovery.hub_switch_model = '';
					this.discovery.ont_model = res.information.model;
					this.discovery.hub_switch_ip_address = res.parent_information.identifier;
					this.discovery.ont_ip_address = res.information.ip_address;
			});
		});
	}

	public get fsanPhrase(): string {
		return this.conf.customPhrases.fsan ?? 'FSAN';
	}
	public get repairPhrase(): string {
		return this.conf.customPhrases.Repairs;
	}
	public copyToClipboard(clipboardText: string): void {
		navigator.clipboard.writeText(clipboardText).then(() => this.toast.success(`${clipboardText} Copied to Clipboard`));
	}

}
