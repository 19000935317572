import { Component, OnInit } from '@angular/core';
import { InstallService } from '../../../services/install.service';
import { IInstall } from '../../../services/types';
import { MatDialog } from '@angular/material/dialog';
import { InstallHistoryDetailedComponent } from './install-history-detailed.component';
import { ConfigService } from '../../../services/config.service';
import { UtilityService } from '../../../services/utility.service';

@Component({
	selector: 'app-install-history',
	templateUrl: './install-history.component.html',
	styleUrls: ['./install-history.component.scss'],
})
export class InstallHistoryComponent implements OnInit {

	public readonly install: IInstall;
	public workorders: IInstall[] = [];
	public cols: any[] = [];
	public hideLastComment: boolean;

	constructor(
		private readonly installService: InstallService,
		public dialog: MatDialog,
		private readonly configService: ConfigService,
		private readonly utilityService: UtilityService,
	) {
		this.install = this.installService.currentInstall;
		this.hideLastComment = this.configService.config.hideLastComment;
	}

	public ngOnInit(): void {
		this.getWorkOrders();
		this.cols = [
			{ field: 'type', header: 'Type' },
			{ field: 'created_at', header: 'Date Created' },
			{ field: 'status', header: 'Status' },
			{ field: 'last_comment', header: 'Last Comment' },
		];

		if (this.hideLastComment)
			this.cols.splice(3, 1);

		this.utilityService.scrollToTop();
	}

	public getWorkOrders(): void {
		this.installService.getWorkOrdersPerPremise(this.install.extraInfo?.premiseId).subscribe((w: any) => this.workorders = w.items);
	}

	public openWorkOrder(workOrder: IInstall): void {
		const dialogRef = this.dialog.open(InstallHistoryDetailedComponent, {
			data: { workOrder },
		});

		dialogRef.afterClosed().subscribe();
	}

}
