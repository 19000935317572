import { Injectable } from '@angular/core';
import { DevicesApi } from './api';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ServiceStatusService {
	public constructor(
		private readonly http: HttpClient,
	) {
	}

	public getDeviceDiscoveryFields(serialNumber: string): Observable<any> {
		return this.http.get<any>(DevicesApi.deviceStatus(serialNumber));
	}
}
