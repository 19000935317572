import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HEADER_LOCATION, LOCATION_RICA } from '../../../../_shared/types';
import { RicaService } from '../../../../services/rica.service';
import { IHasErrors, IInstall, ImageUpload, IRicaResponse, IRicaStatus, RicaDocument } from '../../../../services/types';
import { pathify } from '@aex/ngx-toolbox';

@Component({
	selector: 'app-rica',
	templateUrl: './rica.component.html',
	styleUrls: ['./rica.component.scss'],
})
export class RicaComponent implements OnInit, IRicaStatus, IHasErrors {

	@Input() public readonly install: IInstall;
	@ViewChild('step2', {static: true}) private readonly step2: NgForm;

	public readonly files: ImageUpload[] = [];
	public readonly imageTypes = RICA_DOCUMENTS;

	public hasIdDocument: boolean;
	public hasPorDocument: boolean;
	public verifiedById: string;
	public isVerified: boolean;
	public namespace: string;

	constructor(
			private readonly ricaService: RicaService,
	) { }

	public ngOnInit(): void {
		this.namespace = pathify('rica');
		this.ricaService.getRicaStatus(this.install.extraInfo.customerId)
				.subscribe(response => Object.assign(this, response));
	}

	public processUploadResponse(file: ImageUpload, response: HttpResponse<any>): Observable<void> {
		const url = response.headers.get(HEADER_LOCATION);
		const indexOf = url.indexOf(LOCATION_RICA);
		file.docId = url.substring(indexOf + LOCATION_RICA.length + 1);
		const body: Partial<IRicaResponse> = {};
		if (file.type === RicaDocument.ID)
			body.identification_id = file.docId;
		else
			body.residence_id = file.docId;

		return this.ricaService.update(this.namespace, body).pipe(tap(() => {
			if (file.type === RicaDocument.ID)
				this.hasIdDocument = true;
			else
				this.hasPorDocument = true;
		}));
	}

	public submitRica(): void {
		const body: Partial<IRicaResponse> = {
			verified_by_id_number: this.step2.value.verifiedById,
			verified: true,
		};

		this.ricaService.update(this.namespace, body).subscribe(() => this.isVerified = true);
	}

	public get errors(): string[] {
		const result: string[] = [];
		if (!this.hasIdDocument)
			result.push('No RICA Identity Document');
		if (!this.hasPorDocument)
			result.push('No RICA Proof of Residence');
		if (!this.isVerified)
			result.push('RICA Verification Incomplete');
		return result;
	}
}

const RICA_DOCUMENTS = [RicaDocument.ID, RicaDocument.POR];
