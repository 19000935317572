import { Component, Input } from '@angular/core';
import { IInstall, IStock } from '../../../../services/types';

@Component({
	selector: 'app-install-stock',
	template: `
		<app-assets [install]="install" [assetRequirements]="stockRequirements" [assetType]="assetType"></app-assets>
	`,
})
export class InstallStockComponent {

	@Input() public readonly install: IInstall;
	@Input() public readonly stockRequirements: IStock[];

	public assetType = 'Work Order Assets';

}
