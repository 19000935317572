import { animate, state, style, transition, trigger } from '@angular/animations';

const ROUTE_SLIDE_TIMINGS = 1000;

export const ROUTE_SLIDE_TRIGGER = trigger('routeState', [
	state('*', style({opacity: 1})),
	state('void', style({opacity: 0})),
	transition('* => void', [animate(ROUTE_SLIDE_TIMINGS), style({opacity: 0})]),
	transition('void => *', [animate(ROUTE_SLIDE_TIMINGS), style({opacity: 1})]),
]);

export const COMPONENT_SLIDE_TRIGGER = trigger('componentSlide', [
	transition(':enter', [
		style({transform: 'translateX(-100%)', opacity: 0}),
		animate('500ms', style({transform: 'translateX(0)', opacity: 1})),
	]),
	transition(':leave', [
		style({transform: 'translateX(0)', opacity: 1}),
		animate('500ms', style({transform: 'translateX(-100%)', opacity: 0})),
	]),
]);
