
<input type="file" name="cameraInput" capture="camera" #cameraInput accept="file_extension" class="hidden-file-input" (change)="onBarcodeFileSelected($event)" />

<mat-form-field appearance="standard" *ngIf="serialNumbersList.length; else noSerials">
    <mat-label>{{ fsanLabel }}</mat-label>
    <mat-select placeholder="Select serial number" [ngModel]="serialNumber" required (ngModelChange)="serialNumberUpdated($event)">
        <mat-option *ngFor="let sn of serialNumbersList" [value]="sn.value">{{sn.value}}</mat-option>
    </mat-select>
    <button mat-mini-fab matSuffix color="primary" type="button" (click)="captureBarcode()">
        <fa-icon icon="camera" size="lg"></fa-icon>
    </button>
</mat-form-field>

<ng-template #noSerials>
    <mat-form-field appearance="standard">
        <mat-label>{{ fsanLabel }}</mat-label>
        <input matInput [ngModel]="serialNumber" required (ngModelChange)="serialNumberUpdated($event)" />
        <button mat-mini-fab matSuffix color="primary" type="button" (click)="captureBarcode()">
            <fa-icon icon="camera" size="lg"></fa-icon>
        </button>
    </mat-form-field>
</ng-template>

