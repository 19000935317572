import { DialogService, mapToNull, pathify } from '@aex/ngx-toolbox';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { difference, last, remove } from 'lodash';
import { POSITION } from 'ngx-ui-loader';
import { tap } from 'rxjs/operators';
import { ConfigService } from '../../../../services/config.service';
import { IFnoConfiguration, IHasErrors, IInstall, IUploadedImage } from '../../../../services/types';
import { UploadService } from '../../../../services/upload.service';
import { imageTypeToNamespace, namespaceToImageType } from '../../../../services/utils';
import { ImageUploadComponent } from './image-upload/image-upload.component';

@Component({
	selector: 'app-install-image-uploads',
	templateUrl: './install-image-uploads.component.html',
	styleUrls: ['./install-begin.scss', './install-image-uploads.component.scss'],
})
export class InstallImageUploadsComponent implements OnInit, IHasErrors {

	@Input() public install: IInstall;
	@ViewChild(ImageUploadComponent, {static: true}) private readonly imageUploads: ImageUploadComponent;

	public readonly conf: IFnoConfiguration;
	public readonly LoaderPosition = POSITION;
	public readonly loadingFilesLoaderId = 'image-upload-loading-files';

	public imagesUploaded: IUploadedImage[] = [];
	public imageTypes = DEFAULT_IMAGE_TYPES;

	private requiredImageTypes: string[] = [];
	public namespace: string;

	constructor(
			private readonly uploadService: UploadService,
			private readonly dialog: DialogService,
			configService: ConfigService,
	) {
		this.conf = configService.config;
		uploadService.filesChangedEvent.subscribe(() => this.getAlreadyUploadedFiles());
	}

	public ngOnInit(): void {
		this.namespace = pathify('work_orders', this.install.reference, 'pre_work');
		if(this.install.extraInfo?.requiredImages.length > 0)
			this.imageTypes = this.install.extraInfo.requiredImages;

		switch (this.conf.imageRequirements) {
			case 'all':
				this.requiredImageTypes = this.imageTypes;
				break;
			case 'exceptOptional':
				this.requiredImageTypes = this.imageTypes.filter(item => !item.includes('Optional'));
				break;
			case 'exceptOther':
				this.requiredImageTypes = this.imageTypes.filter(item => item !== 'Other');
				break;
			default:
				this.requiredImageTypes = null;
		}

		this.getAlreadyUploadedFiles();
	}

	private getAlreadyUploadedFiles(): void {
		const namespaces = this.imageTypes.map(type => pathify(this.namespace, imageTypeToNamespace(type)));
		this.uploadService.getFiles({loader: this.loadingFilesLoaderId, isBackground: true}, ...namespaces).pipe(
				tap(images => {
					this.imagesUploaded = images.map(image => ({
						...image,
						type: namespaceToImageType(last(image.namespace.split('/'))),
					}));
					this.imageUploads.removeFilesByName(...this.imagesUploaded.map(f => f.filename));
				}),
				mapToNull(),
		).subscribe();
	}

	public downloadFile(image: IUploadedImage): void {
		this.uploadService.downloadFile(image.id, image.filename, {loader: this.loadingFilesLoaderId, isBackground: true});
	}

	public deleteFile(image: IUploadedImage): void {
		this.dialog.yesNo({title: 'Delete File', message: 'Are you sure you want to delete this file?'}).subscribe(result => {
			if (result)
				this.uploadService.deleteFile(image.guid, {loader: this.loadingFilesLoaderId, isBackground: true})
						.subscribe(() => remove(this.imagesUploaded, i => i.guid === image.guid));
		});
	}

	private get missingTypes(): string[] {
		return difference(this.requiredImageTypes, this.imagesUploaded.map(i => i.type));
	}

	public get errors(): string[] {
		return this.missingTypes.map(t => `Missing Upload: ${ t }`);
	}
}

const DEFAULT_IMAGE_TYPES = [
	'Power metre reading',
	'Indoor fibre termination box and Nano',
	'ONU Picture on wall',
	'FSAN Serial Number',
	'Router F number',
	'Other',
];
