import { LiveMockService } from '@aex/ngx-toolbox';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class MockRegistrations {

	constructor(
			mockService: LiveMockService,
	) {
		mockService.merge([]);

		try {
			const local = require('./my-mock/mock');
			local?.provision?.apply(this, [mockService]);
		} catch {}
	}
}

