import { Component, OnInit } from '@angular/core';
import { InstallService } from '../../../services/install.service';
import { IInstall, ICompany, ICompanyPerson, InstallType } from '../../../services/types';
import { MatDialog } from '@angular/material/dialog';
import { Guid } from 'guid-typescript';
import { NavigationService } from '@aex/ngx-toolbox';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { APP_ROUTES } from '../../../_shared/types';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';

@Component({
	selector: 'app-install-reassign',
	templateUrl: './install-reassign.component.html',
	styleUrls: ['./install-reassign.component.scss'],
})

export class InstallReassignComponent implements OnInit {
	public readonly install: IInstall;
	public companies: ICompany[];
	public companyPeople: ICompanyPerson[];
	public comments: string;

	public selectedCompany: ICompany;
	public selectedCompanyPerson: ICompanyPerson;

	constructor(
		private readonly userService: UserService,
		private readonly installService: InstallService,
		private readonly navigationService: NavigationService,
		private readonly router: Router,
		private readonly toast: ToastrService,
		public dialog: MatDialog,
	) {
		this.install = this.installService.currentInstall;
	}

	public ngOnInit(): void {
		this.installService.getCompanies(Guid.parse(this.install.id), this.install.status_id)
			.subscribe((result) => {
				this.companies = result;
				this.selectedCompany = this.companies.filter(item => item.assigned_id?.toString() === this.install.assigned_id?.toString())[0];
			});

		this.installService.getCompanyPeople(Guid.parse(this.install.id), this.install.status_id, this.install.assigned_id)
			.subscribe((result) => {
				this.companyPeople = result;
				this.selectedCompanyPerson = this.companyPeople.filter(item => item.assigned_user_id?.toString() === this.install.assigned_user_id?.toString())[0];
			});
	}

	public onCompanyChange(event: any): void {
		const company = event.value;
		this.installService.getCompanyPeople(Guid.parse(this.install.id), this.install.status_id, company.assigned_id)
			.subscribe((result) => {
				this.companyPeople = result;
				this.selectedCompanyPerson = null;
				this.selectedCompany = company;
			});
	}

	public onCompanyPersonChange(event: any): void {
		const companyPerson = event.value;
		this.selectedCompanyPerson = companyPerson;
	}

	public reassignWorkOrder(): void {
		const installUpdate = {
			assigned_id: this.selectedCompany.assigned_id,
			assigned: this.selectedCompany.assigned,
			assigned_user_id: this.selectedCompanyPerson?.assigned_user_id ?? null,
			assigned_user: this.selectedCompanyPerson?.assigned_user ?? '',
			comments: this.comments,
			modified_by_id: this.userService.userId?.toString(),

		};

		// Update the Assigned Company and Company Person
		this.installService.updateInstall(this.install.id, InstallType.ALL, installUpdate).pipe(tap((install) => {
				this.install.assigned_id = install.assigned_id;
				this.install.assigned = install.assigned;
				this.install.assigned_user_id = install.assigned_user_id;
				this.install.assigned_user = install.assigned_user;
				this.install.comments = install.comments;
				this.install.modified_by_id = install.modified_by_id;

				this.toast.success('Work Order successfully updated');
				this.router.navigateByUrl(APP_ROUTES.installs.path);
			}),
		).subscribe();

		this.navigationService.stopLoading();
	}
}