import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ParamMetaData } from '../_shared/param-meta-data';
import { HEADER_LOCATION, LOCATION_RICA } from '../_shared/types';
import { serverUrl } from '../_shared/utils';
import { RicaApi } from './api';
import { IRicaResponse, IRicaStatus } from './types';

@Injectable({providedIn: 'root'})
export class RicaService {

	constructor(
			private readonly http: HttpClient,
	) {}

	public getRicaStatus(customerId: string): Observable<IRicaStatus> {
		return this.http.post(
				RicaApi.customer(customerId),
				{rica: {customer_guid: customerId}},
				{observe: 'response'},
		).pipe(
				map(response => {
					const url = response.headers.get(HEADER_LOCATION);
					const indexOf = url.indexOf(LOCATION_RICA);
					return url.substring(indexOf - 1);
				}),
				switchMap(namespace => this.http.get<IRicaResponse>(serverUrl(namespace)).pipe(
						map(response => ({
							hasIdDocument: !!response.identification_id,
							hasPorDocument: !!response.residence_id,
							verifiedById: response.verified_by_id_number,
							isVerified: response.verified,
							namespace,
						})),
						),
				),
		);
	}

	public update(namespace: string, body: Partial<IRicaResponse>): Observable<void> {
		return this.http.put<void>(serverUrl(namespace), {rica: body}, {params: new ParamMetaData({handleError: 'files'})});
	}
}