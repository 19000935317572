import { AuthService } from './auth.service';
import { Guid } from 'guid-typescript';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UserService {
	private readonly userToken: any;
	constructor(
		private readonly authService: AuthService,
	) {
		try {
			this.userToken = this.authService.authToken !== null ? JSON.parse(atob(this.authService.authToken.split('.')[1])) : null;
		} catch (error) {
			// do nothing
		}
	}

	public get userId() : Guid {
		return this.userToken?.uid !== null ? Guid.parse(this.userToken.uid) : null;
	}
}

