import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { APP_ROUTES } from './_shared/types';
import { InstallBeginComponent } from './components/installs/install-detail/install-begin/install-begin.component';
import { InstallReassignComponent } from './components/installs/install-detail/install-reassign.component';
import { InstallCancelComponent } from './components/installs/install-detail/install-cancel.component';
import { InstallHistoryComponent } from './components/installs/install-detail/install-history.component';
import { InstallHoldComponent } from './components/installs/install-detail/install-hold.component';
import { InstallDetailComponent } from './components/installs/install-detail/install-detail.component';
import { InstallRescheduleComponent } from './components/installs/install-detail/install-reschedule.component';
import { InstallsComponent } from './components/installs/installs.component';
import { LoginRedirectComponent } from './components/login-redirect.component';
import { LoginComponent } from './components/login.component';
import { AuthGuard } from './services/auth.service';
import { GetInstallExtraInfoResolver, GetInstallsResolver, GetWorkOrderResolver } from './services/install.service';
import { GetServiceAssetsResolver, GetStockRequirementsResolver } from './services/stock.service';
import { ManagePremisesComponent } from './components/manage-premises/manage-premises.component';

export const routes: Route[] = [
	{path: '', redirectTo: APP_ROUTES.login.name, pathMatch: 'full'},
	{
		path: APP_ROUTES.loginRedirect.name,
		component: LoginRedirectComponent,
	},
	{
		path: APP_ROUTES.installs.name,
		canActivate: [AuthGuard],
		component: InstallsComponent,
		resolve: {
			installs: GetInstallsResolver,
		},
	},
	{
		path: APP_ROUTES.managePremises.name,
		canActivate: [AuthGuard],
		component: ManagePremisesComponent,
	},
	{
		path: `${ APP_ROUTES.install.name }/:type/:id`,
		canActivate: [AuthGuard],
		component: InstallDetailComponent,
		resolve: {
			workOrder: GetWorkOrderResolver,
		},
		children: [
			{
				path: 'begin',
				component: InstallBeginComponent,
				resolve: {
					workOrder: GetInstallExtraInfoResolver,
					stockRequirements: GetStockRequirementsResolver,
					serviceAssets: GetServiceAssetsResolver,
				},
			},
			{path: 'reassign', component: InstallReassignComponent},
			{path: 'cancel', component: InstallCancelComponent},
			{path: 'hold', component: InstallHoldComponent},
			{path: 'reschedule', component: InstallRescheduleComponent},
			{
				path: 'workorder-history',
				component: InstallHistoryComponent,
				resolve: {
					service: GetInstallExtraInfoResolver,
				},
			},
		],
	},
	{
		path: APP_ROUTES.login.name,
		component: LoginComponent,
		data: {title: 'Login'},
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {paramsInheritanceStrategy: 'always'})],
	exports: [RouterModule],
})
export class AppRoutingModule {}
