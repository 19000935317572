<div class="install-client" fxLayout="column" fxLayoutGap=".5rem">
	<div class="client-header" fxLayout fxLayoutAlign="space-between center">
		<div [ngSwitch]="install.type_id">
			<div *ngSwitchCase="workOrderType.INSTALL">Install Status Information</div>
			<div *ngSwitchCase="workOrderType.REPAIR">Repair Status Information</div>
			<div *ngSwitchCase="workOrderType.PREORDER">Pre-Order Status Information</div>
			<div *ngSwitchCase="workOrderType.RELOCATION">Relocation Status Information</div>
		</div>
		<fa-icon icon="check-circle"></fa-icon>
	</div>
	<div class="client-info">
		<div *ngIf="!fsanSaved, else noFsan">
			<h4>Hub/Hut Facility</h4>
			<div><span (click)="copyToClipboard(discovery.hub_facility)">{{ discovery.hub_facility }}</span></div>
			<h4>Hub Number</h4>
			<div><span (click)="copyToClipboard(discovery.hub_number)">{{ discovery.hub_number }}</span></div>
			<h4>Hub Switch Blade</h4>
			<div><span (click)="copyToClipboard(discovery.hub_switch_blade.toString())">{{ discovery.hub_switch_blade }}</span></div>
			<h4>Hub Switch Port</h4>
			<div><span (click)="copyToClipboard(discovery.hub_switch_port.toString())">{{ discovery.hub_switch_port }}</span></div>
			<h4>Hub Switch Model</h4>
			<div><span (click)="copyToClipboard(discovery.hub_switch_model)">{{ discovery.hub_switch_model }}</span></div>
			<h4>Hub Switch IP Address</h4>
			<div><span (click)="copyToClipboard(discovery.hub_switch_ip_address)">{{ discovery.hub_switch_ip_address }}</span></div>
			<h4 class="red">Hub Rx Levels <fa-icon icon="exclamation-triangle"></fa-icon></h4>
			<div><span (click)="copyToClipboard(discovery.hub_rx_levels)" class="red">{{ discovery.hub_rx_levels }}</span></div>
			<h4 class="green">Hub Tx Levels</h4>
			<div><span (click)="copyToClipboard(discovery.hub_tx_levels)" class="green">{{ discovery.hub_tx_levels }}</span></div>
			<h4>ONT Model</h4>
			<div><span (click)="copyToClipboard(discovery.ont_model)">{{ discovery.ont_model }}</span></div>
			<h4>ONT IP Address</h4>
			<div><span (click)="copyToClipboard(discovery.ont_ip_address)">{{ discovery.ont_ip_address }}</span></div>
			<!--The hidden fields below needs to be looked at post-live, as per Jasper's suggestion-->
			<h4 hidden class="green">Gateway Rx Levels</h4>
			<div hidden><span (click)="copyToClipboard(discovery.gateway_rx_levels)" class="green">{{ discovery.gateway_rx_levels }}</span></div>
			<h4 hidden class="green">Gateway Tx Levels</h4>
			<div hidden><span (click)="copyToClipboard(discovery.gateway_tx_levels)" class="green">{{ discovery.gateway_tx_levels }}</span></div>
		</div>
		<ng-template #noFsan>
			<p>Please note that you need to save the {{ fsanPhrase }} before you can view the Status.</p>
		</ng-template>

	</div>
</div>
