import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {isNil} from 'lodash';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {COMPONENT_SLIDE_TRIGGER} from '../../../_shared/animations';
import {APP_ROUTES} from '../../../_shared/types';
import {ConfigService} from '../../../services/config.service';
import {FrameworkService} from '../../../services/framework.service';
import {MapService} from '../../../services/map.service';
import {IFnoConfiguration, IInstall, InstallType} from '../../../services/types';
import {installTypeToStr} from '../../../services/utils';
import {pathify} from '@aex/ngx-toolbox';
import {StockService} from '../../../services/stock.service';
import {Guid} from 'guid-typescript';
import {UtilityService} from '../../../services/utility.service';

@Component({
	templateUrl: './install-detail.component.html',
	styleUrls: ['./install-detail.component.scss'],
	animations: [COMPONENT_SLIDE_TRIGGER],
})
export class InstallDetailComponent implements OnInit {

	public install: IInstall;
	public installActions: IInstallActions = {};
	public canEdit = false;
	public isOnChildRoute = false;
	public isInProgress = false;
	public installType: string;
	public layer3Enabled: boolean;
	private readonly conf: IFnoConfiguration;
	private routeUrl: string;

	constructor(
		private readonly geolocation: MapService,
		private readonly router: Router,
		route: ActivatedRoute,
		frameworkService: FrameworkService,
		private readonly stockService: StockService,
		public readonly configService: ConfigService,
		private readonly utilityService: UtilityService,
	) {
		this.conf = configService.config;
		route.url.subscribe(url => {
			this.routeUrl = pathify(...url.map(u => u.path));
			this.isOnChildRoute = !!route.children.length;
		});

		route.data.subscribe(({ workOrder }) => {
			this.install = workOrder;
			this.isInProgress = Guid.parse(this.install.status_id).equals(this.configService.beginStatus(this.install.type_id));
			const title = installTypeToStr(this.install.type_id, configService.config.customPhrases , true);
			this.layer3Enabled = this.conf.displayLayer3 && this.install.type_id !== InstallType.NIDINSTALL;
			frameworkService.title = `${title} - ${this.install.status}`;

			this.installActions = Object.values(this.configService.finishStatus(this.install.type_id)).includes(Guid.parse(this.install.status_id)) ? {} : {
				begin: true,
				reassign: this.conf.reassign,
				reschedule: this.conf.reschedule,
				cancel: this.conf.cancel,
				hold: this.conf.onHold,
			};

			this.doRefreshLocation().subscribe(canEdit => {
				if (canEdit && this.isInProgress)
					this.navigateTo('begin');
			});
		});

	}

	public ngOnInit(): void {
		if (this.install.type_id !== InstallType.NIDINSTALL) {
			this.stockService.getInstallTypeValues(this.install.id)
				.subscribe(stock => {
					if (stock?.attributes.length > 0) {
						const attributes = stock?.attributes as any[];
						this.installType = attributes.find(res => res.install_type)?.install_type;
					}
				});

			this.utilityService.scrollToTop();
		}

	}

	public get beginBtnCaption(): string {
		return this.isInProgress ? 'Continue' : 'Begin';
	}

	public get anyAction(): boolean {
		return Object.values(this.installActions).some(v => v);
	}

	public navigateTo(route: string): void {
		const params = this.installType ? { installType: this.installType } : {};
		this.router.navigate([APP_ROUTES.install.path, this.install.type_id, this.install.id, route, params])
			.then();
	}

	public startInstall(): void {
		const proximity = this.isInProgress && !isNil(this.conf.proximity)
			? this.doRefreshLocation()
			: of(true);

		proximity.subscribe(canEdit => {
			if (canEdit)
				this.navigateTo('begin');
		});
	}

	private doRefreshLocation(): Observable<boolean> {
		return this.geolocation.isCloseEnough(this.install, this.conf.proximity, true)
			.pipe(tap(closeEnough => this.canEdit = closeEnough));
	}

	public refreshLocation(): void {
		this.doRefreshLocation().subscribe();
	}

	public goBack(): void {
		this.router.navigateByUrl(this.routeUrl).then();
	}

	public goBackToInstalls(): void {
		this.router.navigateByUrl(APP_ROUTES.installs.path).then();
	}
}

interface IInstallActions {
	begin?: boolean,
	reassign?: boolean,
	reschedule?: boolean,
	cancel?: boolean,
	hold?: boolean,
}
