import { clearAllDataCaches, IAuthResponse, isErrorHandled, isTokenExpired, mapToNull } from '@aex/ngx-toolbox';
import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { toSnake } from 'snake-camel';
import { ParamMetaData } from '../_shared/param-meta-data';
import { APP_ROUTES } from '../_shared/types';
import { AuthApi } from './api';
import { ConfigService } from './config.service';

const KEY_AUTH_TOKEN = 'auth-token';

@Injectable({providedIn: 'root'})
export class AuthService {

	constructor(
			private readonly router: Router,
			private readonly http: HttpClient,
			private readonly configService: ConfigService,
			private readonly msAuthService: MsalService,
			private readonly toast: ToastrService,
			msalBroadcastService: MsalBroadcastService,
	) {
		msalBroadcastService.msalSubject$
				.pipe(
						filter((msg: EventMessage) => [EventType.ACQUIRE_TOKEN_SUCCESS, EventType.LOGIN_SUCCESS].includes(msg.eventType)),
						switchMap((msg: EventMessage) => this.processMsAuthToken(msg.payload as AuthenticationResult)),
						catchError(error => {
							this.router.navigateByUrl(APP_ROUTES.login.path).then();
							this.toast.warning(error.message);
							return EMPTY;
						}),
				).subscribe(() => this.router.navigateByUrl(APP_ROUTES.installs.path));
	}

	private _authToken: string;
	public get authToken(): string {
		this._authToken = this._authToken || this.configService.retrieve(KEY_AUTH_TOKEN);
		if (this._authToken && isTokenExpired(this._authToken))
			this._authToken = null;

		return !this._authToken ? null : this._authToken;
	}

	public set authToken(value: string) {
		this._authToken = value;
		this.configService.store(KEY_AUTH_TOKEN, value);
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - because the app doesn't handle direct routing
private _returnUrl: string;
public set returnUrl(value: string) {
	this._returnUrl = value;
}

public get afterLoginUrl(): string {
	return null; // should be prefixed by this._returnUrl ?? but the app doesn't seem to handle direct routinh
}

public get isLoggedIn(): boolean {
	return !!this.authToken;
}

public logout(): Observable<void> {
	this.authToken = null;
	localStorage.clear();
	sessionStorage.clear();
	return clearAllDataCaches();
}

private processMsAuthToken(payload: AuthenticationResult): Observable<void> {
	return this.http.post<IAuthResponse>(AuthApi.azureActiveDirectoryAuth, toSnake(payload), {
		headers: new HttpHeaders().set('X-OPERATOR-ID', this.configService.fnoName),
		params: new ParamMetaData({handleError: 'authentication'}),
	}).pipe(
		tap(res => this.authToken = res.auth_token),
		mapToNull(),
	);
}

public login(username: string, password: string): Observable<void> {
	const body = new URLSearchParams({username, password});

	return this.http.post<IAuthResponse>(AuthApi.auth, body.toString(), {
		headers: new HttpHeaders()
			.set('X-OPERATOR-ID', this.configService.fnoName)
			.set('Content-Type', 'application/x-www-form-urlencoded')
			.set('X-Requested-With', 'XMLHttpRequest'),
		params: new ParamMetaData({handleError: 'authentication'}),
	}).pipe(
		tap(res => this.authToken = res.auth_token),
		mapToNull(),
	);
}

public gotoLogin(returnUrl?: string): void {
	this.router.navigate([APP_ROUTES.login.path, {returnUrl: returnUrl || this.router.url}]).then();
}

public loginWithMicrosoft(): Observable<void> {
	const request: RedirectRequest = {
	redirectStartPage: this.afterLoginUrl ? `${ REDIRECT_URI };returnUrl=${ encodeURI(this.afterLoginUrl) }` : REDIRECT_URI,
	scopes: [],
};
return this.msAuthService.loginRedirect(request);
}

}

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

	constructor(
		private readonly authService: AuthService,
	) {}

	public canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		if (!this.authService.isLoggedIn) {
			this.authService.gotoLogin(state.url);
			return false;
		} else
			return true;
	}

}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	constructor(
		private readonly router: Router,
		private readonly authService: AuthService,
	) {}

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		const handled = this.authService.isLoggedIn
			? next.handle(req.clone({
				headers: req.headers.append('Authorization', `Bearer ${ this.authService.authToken }`),
				reportProgress: true,
			}))
			: next.handle(req);

		return handled.pipe(
			catchError(error => {
				if (!isErrorHandled(error) && error.status === HttpStatusCode.Unauthorized && error.url.includes('/auth'))
					this.router.navigateByUrl(APP_ROUTES.login.path).then();
				return throwError(error);
			}),
		);
	}
}

const IS_IE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
const URL = window.location.href.split('/');
const REDIRECT_URI = `${ URL[0] }//${ URL[2] }/login-redirect`;

export const MSAL_CLIENT_APP = new PublicClientApplication({
	auth: {
		clientId: '17d9518b-1d6e-49a5-a10c-7da179e1edc6',
		authority: 'https://login.microsoftonline.com/common',
		redirectUri: REDIRECT_URI,
	},
	cache: {
		cacheLocation: 'localStorage',
		storeAuthStateInCookie: IS_IE,
	},
});
