import { getLocalStoreObject, IPagedResponse, isNonNull, setLocalStoreItem } from '@aex/ngx-toolbox';
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { addDays, endOfDay, parseISO, startOfDay } from 'date-fns';
import { assign, cloneDeep } from 'lodash';
import { BehaviorSubject, Observable, of, throwError, zip } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { TSMap } from 'typescript-map';
import { ParamMetaData } from '../_shared/param-meta-data';
import { isDateInRange } from '../_shared/utils';
import { ServicesApi, AssetsApi, DevicesApi, InstallsApi, WorkOrderApi, WorkOrderHistoryApi } from './api';
import { ConfigService } from './config.service';
import { MapService } from './map.service';
import {
	DateFilterType,
	IAccount,
	IAccountContainer,
	IAccountUpdate,
	IAttributeResponse,
	IFilteredInstalls,
	IInstall,
	IInstallFilter,
	IInstallUpdate,
	InstallStatusFilter,
	InstallType,
	IService,
	ICompany,
	ICompanyPerson, NidInstallStatusFilter,
} from './types';
import { strEnumToArray } from './utils';
import { Guid } from 'guid-typescript';

@Injectable({ providedIn: 'root' })
export class InstallService {

	private readonly filterSubject = new BehaviorSubject<IInstallFilter>(getFilterFromLocalStore());
	public readonly filterStream = this.filterSubject.asObservable();

	private readonly currentInstallSubject = new BehaviorSubject<IInstall>(null);
	public readonly currentInstallStream = this.currentInstallSubject.asObservable();
	public showNidInstalls: boolean;
	constructor(
		private readonly http: HttpClient,
		private readonly mapService: MapService,
		private readonly configService: ConfigService,
	) { }

	public get filter(): IInstallFilter {
		return this.filterSubject.getValue();
	}

	private installsFromServer: IInstall[];

	public get currentInstall(): IInstall { return this.currentInstallSubject.getValue(); }
	public get installMeta(): ParamMetaData { return new ParamMetaData({ handleError: 'installs' }); }

	public getInstalls(installFilter: IInstallFilter, loader?: string, force: boolean = true): Observable<IFilteredInstalls> {
		return (force || !this.installsFromServer)
			? this.getAllInstalls(installFilter, loader, force)
			: of(this.getLocalInstalls(installFilter));
	}

	public getAllInstalls(installFilter: IInstallFilter, loader?: string, force: boolean = true): Observable<IFilteredInstalls> {
		const initialParams = new ParamMetaData({ handleError: 'installs', loader }, { count: GET_INSTALLS_COUNT });
		this.showNidInstalls = this.configService.config.showNidInstalls ?? false;
		return zip(
			this.mapService.getLocation(loader, force),
			this.installs(installFilter, initialParams),
			this.preOrders(installFilter, initialParams),
			// this.relocation(installFilter, initialParams),
			this.repairs(installFilter, initialParams),
			this.faults(installFilter, initialParams),
			this.premiumRepairs(installFilter, initialParams),
			this.premiumFaults(installFilter, initialParams),
			this.showNidInstalls ? this.nidInstalls(installFilter, initialParams) : of(null),
		).pipe(
			map(([
				location,
				installs,
				repairs,
				preOrders,
				// relocation,
				faults,
				premiumRepairs,
				premiumFaults,
				nidInstalls,
			]) => {
				return (installs?.items ?? [])
					.concat(repairs?.items ?? [])
					.concat(preOrders?.items ?? [])
					// .concat(relocation?.items ?? [])
					.concat(faults?.items ?? [])
					.concat(premiumRepairs?.items ?? [])
					.concat(premiumFaults?.items ?? [])
					.concat(nidInstalls?.items ?? [])
					.map((install: IInstall) => ({
						...install,
						map_url: this.mapService.getMapUrl(install.address),
						status_class: install.status.replace(/\s+/g, '-').toLowerCase(),
						distance: location ? this.mapService.calculateDistance(location, install) : install.distance,
					}));
			}),
			tap(installs => this.installsFromServer = installs),
			map(() => this.getLocalInstalls(installFilter)),
		);
	}

	public setDateParams(installFilter: IInstallFilter, initialParams: ParamMetaData): ParamMetaData {
		const normalizedFilter = normaliseFilter(installFilter);
		let params = initialParams;

		if (normalizedFilter.startDate)
			params = params.set('start_date', startOfDay(normalizedFilter.startDate).toISOString());
		if (normalizedFilter.endDate)
			params = params.set('end_date', endOfDay(normalizedFilter.endDate).toISOString());

		return params;
	}

	public installs(installFilter: IInstallFilter, initialParams: ParamMetaData): Observable<IPagedResponse<IInstall>> {
		let params = cloneDeep(this.setDateParams(installFilter, initialParams));
		params = params.set('status', strEnumToArray(InstallStatusFilter).join(','));

		return this.http.get<IPagedResponse<IInstall>>(InstallsApi.installs, { params });
	}

	public nidInstalls(installFilter: IInstallFilter, initialParams: ParamMetaData): Observable<IPagedResponse<IInstall>> {
		let params = cloneDeep(this.setDateParams(installFilter, initialParams));
		params = params.set('status', strEnumToArray(NidInstallStatusFilter).join(','));

		return this.http.get<IPagedResponse<IInstall>>(InstallsApi.nidInstalls, { params });
	}

	public preOrders(installFilter: IInstallFilter, initialParams: ParamMetaData): Observable<IPagedResponse<IInstall>> {
		const params = cloneDeep(this.setDateParams(installFilter, initialParams));

		return this.configService.config.showPreOrder
			? this.http.get<IPagedResponse<IInstall>>(InstallsApi.preorders, { params })
			: new BehaviorSubject<IPagedResponse<IInstall>>({ items: [], count: 0, page: 1, total: 0 });
	}

	public relocation(installFilter: IInstallFilter, initialParams: ParamMetaData): Observable<IPagedResponse<IInstall>> {
		const params = cloneDeep(this.setDateParams(installFilter, initialParams));

		return this.configService.config.showRelocation
			? this.http.get<IPagedResponse<IInstall>>(InstallsApi.relocations, { params })
			: new BehaviorSubject<IPagedResponse<IInstall>>({ items: [], count: 0, page: 1, total: 0 });
	}

	public repairs(installFilter: IInstallFilter, initialParams: ParamMetaData): Observable<IPagedResponse<IInstall>> {
		const params = cloneDeep(this.setDateParams(installFilter, initialParams));

		return this.http.get<IPagedResponse<IInstall>>(InstallsApi.repairs, { params });
	}

	public premiumRepairs(installFilter: IInstallFilter, initialParams: ParamMetaData): Observable<IPagedResponse<IInstall>> {
		let params = cloneDeep(this.setDateParams(installFilter, initialParams));
		params = params.set('premium', true);

		return this.configService.config.showPremiumRepair
			? this.http.get<IPagedResponse<IInstall>>(InstallsApi.repairs, { params })
			: new BehaviorSubject<IPagedResponse<IInstall>>({ items: [], count: 0, page: 1, total: 0 });
	}

	public faults(installFilter: IInstallFilter, initialParams: ParamMetaData): Observable<IPagedResponse<IInstall>> {
		const params = cloneDeep(this.setDateParams(installFilter, initialParams));
		return this.configService.config.showFault
			? this.http.get<IPagedResponse<IInstall>>(InstallsApi.faults, { params })
			: new BehaviorSubject<IPagedResponse<IInstall>>({ items: [], count: 0, page: 1, total: 0 });
	}

	public premiumFaults(installFilter: IInstallFilter, initialParams: ParamMetaData): Observable<IPagedResponse<IInstall>> {
		let params = cloneDeep(this.setDateParams(installFilter, initialParams));
		params = params.set('premium', true);

		return this.configService.config.showPremiumFault
			? this.http.get<IPagedResponse<IInstall>>(InstallsApi.faults, { params })
			: new BehaviorSubject<IPagedResponse<IInstall>>({ items: [], count: 0, page: 1, total: 0 });
	}

	public saveFilter(installFilter: IInstallFilter): IInstallFilter {
		const lFilter = normaliseFilter(installFilter);
		setLocalStoreItem(FILTER_KEY, installFilter);
		this.filterSubject.next(lFilter);
		return lFilter;
	}

	public getLocalInstalls(installFilter?: IInstallFilter): IFilteredInstalls {

		const lOptions = installFilter ?? this.filter;

		const result: IFilteredInstalls = {
			installs: this.installsFromServer || [],
			aggregates: new TSMap(),
		};

		result.installs = result.installs.filter(install => {
			// Check within date ranges
			return (!lOptions.dateFilterType || isDateInRange(parseISO(install.schedule_date), lOptions, { useDayEdge: true }))
				// Check install type filter
				&& (!lOptions.installType || install.type_id === lOptions.installType);
		});

		result.installs.forEach(install => result.aggregates.set(install.status, (result.aggregates.get(install.status) ?? 0) + 1));

		return result;
	}

	// eslint-disable-next-line complexity
	public getWorkOrder(type: InstallType, id: string): Observable<IInstall> {
		const existing = this.installsFromServer?.find(i => i.id === id);
		let obs: Observable<IInstall>;

		switch (type) {
			case InstallType.REPAIR:
				obs = existing ? of(existing) : this.http.get<IInstall>(InstallsApi.repair(id), { params: this.installMeta });
				break;
			case InstallType.RELOCATION:
				obs = existing ? of(existing) : this.http.get<IInstall>(InstallsApi.relocation(id), { params: this.installMeta });
				break;
			case InstallType.PREORDER:
				obs = existing ? of(existing) : this.http.get<IInstall>(InstallsApi.preorder(id), { params: this.installMeta });
				break;
			case InstallType.FAULT:
				obs = existing ? of(existing) : this.http.get<IInstall>(InstallsApi.fault(id), { params: this.installMeta });
				break;
			case InstallType.PREMIUM_SUPPORT_FAULT:
				obs = existing ? of(existing) : this.http.get<IInstall>(InstallsApi.fault(id), { params: this.installMeta.set('premium', true) });
				break;
			case InstallType.PREMIUM_SUPPORT_REPAIR:
				obs = existing ? of(existing) : this.http.get<IInstall>(InstallsApi.repair(id), { params: this.installMeta.set('premium', true) });
				break;
			case InstallType.NIDINSTALL:
				obs = existing ? of(existing) : this.http.get<IInstall>(InstallsApi.nidInstall(id), { params: this.installMeta });
				break;
			default:
				obs = existing ? of(existing) : this.http.get<IInstall>(InstallsApi.install(id), { params: this.installMeta });
				break;
		}

		return obs.pipe(tap(install => this.currentInstallSubject.next(install)));
	}

	public updateInstall(id: string, type: InstallType, install: Partial<IInstallUpdate>): Observable<IInstall> {
		switch (type) {
			case InstallType.REPAIR:
				return this.http.put<IInstall>(InstallsApi.repair(id), { repair: install }, { params: this.installMeta })
					.pipe(
						tap(response => {
							this.calculateScheduledDateTime(response);
							this.currentInstallSubject.next(assign(this.currentInstall, response));
						}),
					);
			case InstallType.RELOCATION:
				return this.http.put<IInstall>(InstallsApi.relocation(id), { relocation: install }, { params: this.installMeta })
					.pipe(
						tap(response => {
							this.calculateScheduledDateTime(response);
							this.currentInstallSubject.next(assign(this.currentInstall, response));
						}),
					);
			case InstallType.PREORDER:
				return this.http.put<IInstall>(InstallsApi.preorder(id), { pre_order: install }, { params: this.installMeta })
					.pipe(
						tap(response => {
							this.calculateScheduledDateTime(response);
							this.currentInstallSubject.next(assign(this.currentInstall, response));
						}),
					);
			case InstallType.FAULT:
				return this.http.put<IInstall>(InstallsApi.fault(id), { fault: install }, { params: this.installMeta })
					.pipe(
						tap(response => {
							this.calculateScheduledDateTime(response);
							this.currentInstallSubject.next(assign(this.currentInstall, response));
						}),
					);
			case InstallType.PREMIUM_SUPPORT_FAULT:
				return this.http.put<IInstall>(InstallsApi.fault(id), { fault: install }, { params: this.installMeta.set('premium', true) })
					.pipe(
						tap(response => {
							this.calculateScheduledDateTime(response);
							this.currentInstallSubject.next(assign(this.currentInstall, response));
						}),
					);
			case InstallType.PREMIUM_SUPPORT_REPAIR:
				return this.http.put<IInstall>(InstallsApi.repair(id), { repair: install }, { params: this.installMeta.set('premium', true) })
					.pipe(
						tap(response => {
							this.calculateScheduledDateTime(response);
							this.currentInstallSubject.next(assign(this.currentInstall, response));
						}),
					);
			case InstallType.INSTALL:
				return this.http.put<IInstall>(InstallsApi.install(id), { install }, { params: this.installMeta })
					.pipe(
						tap(response => {
							this.calculateScheduledDateTime(response);
							this.currentInstallSubject.next(assign(this.currentInstall, response));
						}),
					);
			case InstallType.NIDINSTALL:
				return this.http.put<IInstall>(InstallsApi.nidInstall(id), { nid_install : install }, { params: this.installMeta })
					.pipe(
						tap(response => {
							this.calculateScheduledDateTime(response);
							this.currentInstallSubject.next(assign(this.currentInstall, response));
						}),
					);
			default:
				return this.http.put<IInstall>(InstallsApi.workOrder(id), { work_order: install }, { params: this.installMeta })
					.pipe(
						tap(response => {
							this.calculateScheduledDateTime(response);
							this.currentInstallSubject.next(assign(this.currentInstall, response));
						}),
					);
		}
	}

	public updateAccount(id: string, service: Partial<IAccountUpdate>): Observable<void> {
		return this.http.put<void>(ServicesApi.service(id), { service }, { params: this.installMeta });
	}

	public getService(id: string): Observable<Partial<IService>> {
		return this.http.get<IService>(ServicesApi.service(id));
	}

	public getAccount(id: string): Observable<Partial<IAccount>> {
		return this.http.get<IAccountContainer>(ServicesApi.fullService(id)).pipe(map(r => r.full_service));
	}

	public validateFsan(fsan: string): Observable<any> {
		return this.http.post<any>(DevicesApi.checkFsan(fsan), null);
	}

	private getRequiredImages(type: string): Observable<string[]> {
		let images = type === 'Aerial' ? this.configService.config.aerialImageTypeList : this.configService.config.imageTypeList;
		images = type === '13'? this.configService.config.nidImageTypeList : images;
		return images?.length
			? of(images)
			: this.http.get<IAttributeResponse>(AssetsApi.images).pipe(
				map(response => response.attributes.map(a => a.description)),
				catchError(error => {
					if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.NotFound)
						return of([]);
					else
						return throwError(error);
				}),
			);
	}

	public getExtraInstallInfo(install: IInstall, type: string = null): Observable<IInstall> {
		return install.extraInfo
			? of(install)
			: zip(
				this.getAccount(install.account_id),
				this.getRequiredImages(type),
			).pipe(
				map(([account, requiredImages]) => {
					install.extraInfo = {
						ispReference: this.configService.config.includeISPRef && account.service?.isp_reference,
						serialNumber: account.premise?.asset_reference,
						providerName: account.provider?.name,
						customerId: account.service.customer_id,
						premiseId: account.service.premise_id,
						serviceLevel: account.service.level,
						validSerialNumbers: [account.premise?.asset_reference].filter(isNonNull),
						inValidSerialNumbers: [],
						requiredImages,
					};
					return install;
				}),
			);
	}

	public getWorkOrdersPerPremise(premiseId: string, count: number = 300, page: number = 1): Observable<IInstall> {
		return this.http.get<IInstall>(WorkOrderHistoryApi.workOrders, {
				params: new ParamMetaData()
					.set('premise_id', premiseId)
					.set('count', count)
					.set('page', page),
			});
	}

	public getWorkOrderHistory(workorderId: string, count: number = 300, page: number = 1): Observable<any> {
		return this.http.get<any>(WorkOrderHistoryApi.workorderHistory(workorderId), {
				params: new ParamMetaData()
					.set('count', count)
					.set('page', page),
			});
	}

	public getCompanies(workOrderId: Guid, statusId: Guid): Observable<ICompany[]> {
		return this.http.get<ICompany[]>(WorkOrderApi.companies(workOrderId, statusId));
	}

	public getCompanyPeople(workOrderId: Guid, statusId: Guid, companyId: Guid): Observable<ICompanyPerson[]> {
		return this.http.get<ICompanyPerson[]>(WorkOrderApi.companyPeople(workOrderId, statusId, companyId));
	}

	private calculateScheduledDateTime(install: IInstall) {
		const dateString = install.schedule_date.split('T')[0];
		const timeString = install.schedule_time.split('T')[1];
		const dateTimeString = `${dateString}T${timeString}`;
		install.scheduled_date_time = new Date(dateTimeString);
	}
}

const FILTER_KEY = 'installs-filter';
const GET_INSTALLS_COUNT = 300;

function getFilterFromLocalStore(): IInstallFilter {
	return normaliseFilter(getLocalStoreObject(FILTER_KEY) || { dateFilterType: DateFilterType.CURRENT_WEEK });
}

@Injectable({ providedIn: 'root' })
export class GetInstallsResolver implements Resolve<IFilteredInstalls> {

	constructor(
		private readonly service: InstallService,
	) { }

	public resolve(): Observable<IFilteredInstalls> {
		return this.service.getInstalls(this.service.filter, null, false).pipe(
			catchError(() => of(null)),
		);
	}

}

@Injectable({ providedIn: 'root' })
export class GetWorkOrderResolver implements Resolve<IInstall> {

	constructor(
		private readonly service: InstallService,
	) { }

	public resolve(route: ActivatedRouteSnapshot): Observable<IInstall> {
		return this.service.getWorkOrder(route.params.type, route.params.id);
	}

}

@Injectable({ providedIn: 'root' })
export class GetInstallExtraInfoResolver implements Resolve<IInstall> {

	constructor(
		private readonly installService: InstallService,
	) { }

	public resolve(route: ActivatedRouteSnapshot): Observable<IInstall> {
		const installType = route.params.type;
		return this.installService.currentInstallStream.pipe(
			first(i => i.id === route.params.id),
			switchMap(install => {
				return install.extraInfo
					? of(install)
					: this.installService.getExtraInstallInfo(install, installType);
			}),
		);
	}

}

export function normaliseFilter(filter: IInstallFilter): IInstallFilter {
	const lFilter = cloneDeep(filter);
	lFilter.installType = lFilter.installType ?? InstallType.ALL;
	lFilter.dateFilterType = lFilter.dateFilterType ?? DateFilterType.CURRENT_WEEK;

	switch (lFilter.dateFilterType) {
		case DateFilterType.CURRENT_WEEK:
			lFilter.startDate = startOfDay(new Date());
			lFilter.endDate = endOfDay(addDays(lFilter.startDate, 7));
			break;
		case DateFilterType.TODAY:
			lFilter.startDate = startOfDay(new Date());
			lFilter.endDate = endOfDay(new Date());
			break;
		case DateFilterType.CUSTOM:
			lFilter.startDate = lFilter.startDate ? new Date(lFilter.startDate) : null;
			lFilter.endDate = lFilter.endDate ? new Date(lFilter.endDate) : null;
			break;
		default:
			lFilter.startDate = null;
			lFilter.endDate = null;
	}

	return lFilter;
}
