<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<form #form=ngForm class="stock-container mat-elevation-z2" (ngSubmit)="form.valid && onSubmit(form)" fxLayout="column">
	<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap.gt-xs=".5rem">
				<span *ngFor="let item of requirements" [fxFlex.gt-xs]="isTextArea(item) ? 97 : 48">
					<span *ngIf="item.description !== 'Power Type'; else powerTypeAsset">
						<mat-form-field appearance="outline" class="assets-field">
							<mat-label>{{ item.description }}</mat-label>
							<input
									*ngIf="!isTextArea(item); else textArea"
									matInput [type]="item.type || 'text'"
									ngModel [name]="item.description" [required]="item.required" [readonly]="disableServiceAssetsUpdate">
							<ng-template #textArea>
								<textarea matInput class="stock-text-area" ngClass.xs="xs-text-area" ngModel [name]="item.description" [required]="item.required"
									[disabled]="disableServiceAssetsUpdate">
								</textarea>
							</ng-template>
						</mat-form-field>
					</span>
					<ng-template #powerTypeAsset>
						<mat-form-field appearance="outline" class="assets-field">
							<mat-label>{{ item.description }}</mat-label>
							<mat-select id="option" [name]="item.description" placeholder="Select Option" ngModel [required]="item.required"
								[disabled]="disableServiceAssetsUpdate">
								<mat-option *ngFor="let option of dropdown" [value]="option">{{ option }}</mat-option>
							</mat-select>
						</mat-form-field>
					</ng-template>
				</span>
	</div>

	<button *ngIf="!disableServiceAssetsUpdate" mat-flat-button color="primary" type="submit" fxFlexAlign="center" class="icon-inside"
		[disabled]="!form.dirty">
		<fa-icon icon="cloud-upload-alt"></fa-icon>
		<span>Save Assets</span>
	</button>
</form>
