import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { ROUTE_SLIDE_TRIGGER } from './_shared/animations';
import { AuthService } from './services/auth.service';
import { FrameworkService } from './services/framework.service';
import { ConfigService } from './services/config.service';
import { GoogleAnalyticsService } from './services/analytics.service';
import { UtilityService } from './services/utility.service';

// eslint-disable-next-line @typescript-eslint/ban-types
declare var gtag: Function;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [ROUTE_SLIDE_TRIGGER],
})
export class AppComponent implements OnInit {
	public title = 'AEx Installer App';
	public env = environment;
	public googleAnalytics = false;
	public googleAnalyticsKey = '';
	public hasGoogleAnalytics4 = false;
	public googleAnalytics4Key = '';
	public enableManagePremises = false;
	public showLiveChat = false;
	private liveChatSrc = '';
	private liveChatId = '';
	private liveChatAttribute = '';

	constructor(
			private readonly authService: AuthService,
			private readonly toast: ToastrService,
			private readonly router: Router,
			public readonly conf: ConfigService,
			public readonly utils: UtilityService,
			private readonly googleAnalyticsService: GoogleAnalyticsService,
			frameworkService: FrameworkService,
	) {
		if (!localStorage) {
			toast.warning(NO_LOCAL_STORE);
			frameworkService.titleStream.subscribe((title) => (this.title = title));
		}

		this.initializeGoogleAnalytics(conf);
		this.initializeLiveChat(conf);
		utils.scrollPositionStream.subscribe(position => this.scrollToPosition(position));
	}

	private initializeGoogleAnalytics(conf: ConfigService): void {
		this.googleAnalytics = conf.config.hasGoogleAnalytics ?? false;
		this.googleAnalyticsKey = conf.config.googleAnalyticsKey ?? '';
		this.hasGoogleAnalytics4 = conf.hasGoogleAnalytics4 ?? false;
		this.googleAnalytics4Key = conf.googleAnalytics4Key ?? '';
		this.enableManagePremises = conf.config.enableManagePremises ?? false;
	}

	private initializeLiveChat(conf: ConfigService): void {
		this.showLiveChat = conf.config.showLiveChat ?? false;
		this.liveChatSrc = conf.config.liveChatSrc ?? '';
		this.liveChatId = conf.config.liveChatId ?? '';
		this.liveChatAttribute = conf.config.liveChatAttribute ?? '';
	}

	private scrollToPosition(position: number): void {
		document.getElementById('main-component').scrollTop = position;
	}

	public ngOnInit(): void {
		if (this.googleAnalytics || this.hasGoogleAnalytics4)
			this.googleAnalyticsService.initialize();
		if (this.showLiveChat)
			this.loadLiveChatWidget();
	}

	private loadLiveChatWidget(): void {
		const script = document.createElement('script');
		script.src = this.liveChatSrc;
		script.id = this.liveChatId;
		script.setAttribute('data-socket-uri', this.liveChatAttribute);
		document.body.appendChild(script);
	}

	public logOut(): void {
		this.authService.logout();
		this.toast.info('You\'ve been logged out');
		this.router.navigateByUrl('login').then();
	}
}

const NO_LOCAL_STORE =
		'This Browser or Device does not support the Local Storage API, Please consider using a device which does.';
