import { InstallType, ICustomPhrases } from './types';
// eslint-disable-next-line complexity
export function installTypeToStr(this: any, type: InstallType, customPhrases: ICustomPhrases, plural: boolean = false): string {
	switch (type) {
		case InstallType.REPAIR:
			return plural ? customPhrases.Repairs : customPhrases.Repair;
		case InstallType.INSTALL:
			return plural ? customPhrases.Installs : customPhrases.Install;
		case InstallType.RELOCATION:
			return plural ? customPhrases.Relocations : customPhrases.Relocation;
		case InstallType.PREORDER:
			return plural ? customPhrases.PreOrders : customPhrases.PreOrder;
		case InstallType.PREMIUM_SUPPORT_FAULT:
			return plural ? customPhrases.PremiumFaults : customPhrases.PremiumFault;
		case InstallType.PREMIUM_SUPPORT_REPAIR:
			return plural ? customPhrases.PremiumRepairs : customPhrases.PremiumRepair;
		case InstallType.NIDINSTALL:
			return plural ? customPhrases.NidInstalls : customPhrases.NidInstall;
		default:
			return plural ? customPhrases.WorkOrders : customPhrases.WorkOrder;
	}
}

export function strEnumToArray(enumeration: any): string[] {
	const keys = Object.keys(enumeration).filter(k => typeof enumeration[k as any] === 'string');
	return keys.map(k => enumeration[k as any]);
}

export function imageTypeToNamespace(type: string): string {
	return type?.replace(' ', '_');
}

export function namespaceToImageType(namespace: string): string {
	return namespace?.replace('_', ' ');
}

