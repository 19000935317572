<!-- Install - Reschedule Form-->
<div class="install-action-container install-history mat-elevation-z2">
    <div class="history-header" fxLayout fxLayoutAlign="space-between center">
        <div>Work Order History</div>
        <fa-icon icon="clipboard-check"></fa-icon>
    </div>

    <p-table [columns]="cols" [value]="workorders" styleClass="p-datatable-responsive-demo"
             [paginator]="true" [rows]="5" [showCurrentPageReport]="true"
             currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" id="col">
                    {{col.header}}
                </th>
                <th id="action">Action</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td *ngFor="let col of columns">
                    <span class="p-column-title">{{col.header}}</span><br>
                    <span *ngIf="col.field === 'created_at', else notDate">{{ (rowData[col.field] | nonIsoDatePipe) || "-"}}</span>
                    <ng-template #notDate>
                        {{ rowData[col.field] || "-"}}
                    </ng-template>
                </td>
                <td><button mat-flat-button color="primary" class="table-button" (click)="openWorkOrder(rowData)">View</button></td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="columns.length">
                    No History to Display
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
