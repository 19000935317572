import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { SignaturePadComponent } from '@almothafar/angular-signature-pad';

@Component({
	selector: 'app-signature-field',
	template: `
		<div #container class="pad-container" (window:resize)="beResponsive()">
			<signature-pad (drawEnd)="drawComplete()"></signature-pad>
		</div>
	`,
	styleUrls: ['./signature-field.component.scss'],
})
export class SignatureFieldComponent implements AfterViewInit {

	@ViewChild('container', {static: true}) public container: ElementRef;
	@ViewChild(SignaturePadComponent, {static: true}) public signaturePad: SignaturePadComponent;

	public hasSignature = false;

	public get signature(): string {
		return this.signaturePad.toDataURL('image/png', 0.5);
	}

	public beResponsive(): void {
		this.setDimensions(this.container.nativeElement.clientWidth, this.container.nativeElement.clientHeight);
	}

	private setDimensions(width: number, height: number): void {
		this.signaturePad.set('canvasWidth', width);
		this.signaturePad.set('canvasHeight', height);
	}

	public ngAfterViewInit(): void {
		this.signaturePad.clear();
		this.beResponsive();
	}

	public drawComplete(): void {
		this.hasSignature = true;
	}

	public clear(): void {
		this.signaturePad.clear();
		this.hasSignature = false;
	}
}
