import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ROUTES } from '../../_shared/types';
import { IInstall } from '../../services/types';
import { ConfigService } from '../../services/config.service';
import { UtilityService } from '../../services/utility.service';

@Component({
	selector: 'app-install-summary',
	templateUrl: './install-summary.component.html',
	styleUrls: ['./install-summary.component.scss'],
})
export class InstallSummaryComponent implements OnInit {

	@Input() public readonly install: IInstall;
	@Input() public readonly onlyOne: boolean;

	public showAddress: boolean;
	public showWorkSummary: boolean;
	public hideScheduledTime: boolean;

	constructor(
		private readonly router: Router,
		private readonly configService: ConfigService,
		private readonly utilityService: UtilityService,
	) {
		if (this.configService.config.displayAddressOnSummary)
			this.showAddress = true;
		this.showWorkSummary = this.configService.config.showWorkSummary;
		this.hideScheduledTime = this.configService.config.hideScheduledTime;
	}

	public ngOnInit(): void {
		this.utilityService.scrollToTop();
	}

	public show(): void {
		this.router.navigate([APP_ROUTES.install.path, this.install.type_id, this.install.id]).then();
	}

	public navigate(): void {
		const url = encodeURI(`https://www.google.com/maps/dir/?api=1&destination=${this.install.navigation_address}`);
		window.open(url, '_blank');
	}
}
