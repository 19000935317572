import { pathify } from '@aex/ngx-toolbox';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { remove } from 'lodash';
import { takeLast } from 'rxjs/operators';
import { FilenameUtils } from '../../../../../_shared/utils';
import { FileUploadStatus, ImageUpload, newFileUploadProgress } from '../../../../../services/types';
import { UploadResponseProcessor, UploadService } from '../../../../../services/upload.service';
import { imageTypeToNamespace } from '../../../../../services/utils';

@Component({
	selector: 'app-image-upload',
	templateUrl: './image-upload.component.html',
	styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent {

	@Input() public imageTypes: string[];
	@Input() public namespace: string;
	@Input() public uploadToNamespace: boolean;
	@Input() public processUploadResponse: UploadResponseProcessor;
	@ViewChild('fileInput', {static: true}) private readonly fileInput: ElementRef;

	public readonly fileUploadStatus = FileUploadStatus;

	public filesToUpload: ImageUpload[] = [];
	public selectedType: string;
	public progress = newFileUploadProgress();

	constructor(
			private readonly uploadService: UploadService,
	) { }

	public onFileSelected(event: any): void {
		const files: File[] = Array.from(event.target.files);
		files.forEach(file => {
			const image = new ImageUpload(
					file,
					this.selectedType,
					FilenameUtils.getTimestampFilename(file.name),
					pathify(this.namespace, imageTypeToNamespace(this.selectedType)),
			);
			this.filesToUpload.push(image);
			this.uploadImages(image);
		});
	}

	public uploadImages(...files: ImageUpload[]): void {
		const lFiles = files.length ? files : this.filesToUpload.filter(f => !this.progress.has(f.guid));
		const namespace = this.uploadToNamespace ? this.namespace : null;
		const progress = this.uploadService.uploadFiles(lFiles, namespace, this.processUploadResponse);
		progress.forEach((value, key) => {
			// Set the original observable to the progress property, we want to to track every progress response
			this.progress.set(key, value);

			// This will ensure everything is cleaned up and refreshed at the end of progress
			value.pipe(takeLast(1)).subscribe(() => {
				this.progress.delete(key);
				if (!this.progress.size())
					this.uploadService.filesChanged();
			});
		});
	}

	public removeFile(file: ImageUpload): void {
		remove(this.filesToUpload, file);
	}

	public chooseFile(): void {
		this.fileInput.nativeElement.click();
	}

	public removeFilesByName(...files: string[]): void {
		remove(this.filesToUpload, f => files.includes(f.filename));
	}

}
