import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable()
export class ServiceWorkerInterceptor implements HttpInterceptor {

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		return req.url.includes('files') || req.url.includes('process/barcode')
				? next.handle(req.clone({
					headers: req.headers.append('ngsw-bypass', 'true'),
				}))
				: next.handle(req);
	}

}