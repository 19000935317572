import { NavigationService } from '@aex/ngx-toolbox';
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { defer } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { IInstall } from 'src/app/services/types';
import { APP_ROUTES } from '../../../_shared/types';
import { ConfigService } from '../../../services/config.service';
import { InstallService } from '../../../services/install.service';

@Component({
	selector: 'app-install-cancel',
	template: `
		<div class="install-action-container mat-elevation-z2">
			<form #form="ngForm" (ngSubmit)="form.valid && submitForm(form)" fxLayout="column" fxLayoutGap="1rem">
				<!-- Cancel Reason -->
				<mat-form-field>
					<mat-select id="reasonField" placeholder="Cancellation Reason" name="reason" ngModel="reason" #reasonField=ngModel required>
						<mat-option *ngFor="let reason of cancelReasons" [value]="reason">{{ reason }}</mat-option>
					</mat-select>
					<mat-error *ngIf="reasonField.errors?.required">Reason is required</mat-error>
				</mat-form-field>
				<!-- Explanation -->
				<mat-form-field *ngIf="form.value.reason === 'Other'">
					<input id="otherField" matInput type="text" placeholder="Explanation" name="other" ngModel #otherField=ngModel required>
					<mat-error *ngIf="otherField.errors?.required">You must provide and explanation</mat-error>
				</mat-form-field>

				<button mat-flat-button color="primary" type="submit" class="icon-inside" fxFlexAlign="end">
					<fa-icon icon="times-circle"></fa-icon>
					<span>Cancel {{ install.type_id | installType }}</span>
				</button>
			</form>
		</div>
	`,
	styleUrls: ['./install-action.scss'],
})
export class InstallCancelComponent {

	public readonly install: IInstall;
	getCancelReasons(): string[] {
		if (this.configService.config.customReasonDropDown?? false)
			return this.configService.config.typeTypeCancelledList;
		else
			return [
				'Client did not show up for appointment',
				'Termination access – away from client premises',
				'Client doesn’t want the install',
				'Fibre repair to be booked',
				'Power metre reading too high',
				'Other',
			];
	}
	public readonly cancelReasons = this.getCancelReasons();

	constructor(
		private readonly router: Router,
		private readonly installService: InstallService,
		private readonly toast: ToastrService,
		private readonly nav: NavigationService,
		private readonly configService: ConfigService,
	) {
		this.install = installService.currentInstall;
	}

	public submitForm(form: NgForm): void {
		const targetStatus = this.configService.cancelStatus(this.install.type_id).toString();
		let comments = form.value.reason;
		if (comments === 'Other')
			comments = `${ comments } - ${ form.value.explanation }`;

		const obj = {status_id: targetStatus, comments};

		this.nav.startLoading();
		this.installService.updateInstall(this.install.id, this.install.type_id, obj).pipe(
			finalize(() => defer(() => this.nav.stopLoading())),
		).subscribe(res => {
			if (res.status_id === targetStatus) {
				this.router.navigateByUrl(APP_ROUTES.installs.path).then();
				this.toast.success(`Install state changed to ${ res.status } and updated`);
			} else
				this.toast.warning('State mismatch please refresh and try again');
		});
	}
}