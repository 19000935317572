import { NavigationService, WithDestroy } from '@aex/ngx-toolbox';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { head } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { UploadService } from 'src/app/services/upload.service';
import { FilenameUtils } from 'src/app/_shared/utils';
import { ConfigService } from 'src/app/services/config.service';
import { ImageUpload, ISerialNumber } from '../../../services/types';
import { UtilityService } from '../../../services/utility.service';

@Component({
	selector: 'app-barcode-scanner',
	templateUrl: './barcode-scanner.component.html',
	styleUrls: ['./barcode-scanner.component.scss'],
})

export class BarcodeScannerComponent extends WithDestroy() implements OnChanges {
	@Input() public referrence: any;
	@Input() public serialNumber: string;
	@Output() readonly serialNumberChange = new EventEmitter<string>();
	@ViewChild('cameraInput', { static: false }) private readonly cameraInput: ElementRef;

	public serialNumbersList: ISerialNumber[] = [];

	public fsanLabel = this.configService.config.customPhrases?.fsan ?? 'FSAN';

	constructor(
		private readonly nav: NavigationService,
		private readonly configService: ConfigService,
		private readonly utilityService: UtilityService,
		private readonly uploadService: UploadService,
		private readonly toast: ToastrService,
	) {
		super();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['serialNumber']) {
			this.serialNumber = changes['serialNumber']?.currentValue;
			this.serialNumberChange.emit(this.serialNumber);
		}

		this.serialNumbersList = [];
		if (this.cameraInput?.nativeElement !== null && this.cameraInput?.nativeElement !== undefined)
			this.cameraInput.nativeElement.value = '';
	}

	serialNumberUpdated(value: string) {
		this.serialNumber = value;
		this.serialNumberChange.emit(value);
	}

	public onBarcodeFileSelected(event: any): void {
		if (event.target.files?.length) {
			const file: File = head(event.target.files);
			const reader = new FileReader();

			reader.onload = () => {
				const type = 'FSAN Serial Number';
				const image = new ImageUpload(file, type, FilenameUtils.getTimestampFilename(file.name));

				const data: FormData = image.getFormData();

				this.utilityService.processBarcode(data).pipe(
					tap(() => this.uploadService.filesChanged()),
				).subscribe(serials => {
					this.serialNumbersList = serials;

					switch (this.serialNumbersList.length) {
						case 0:
							this.toast.warning(`No serial numbers Found`);
							break;
						case 1:
							this.serialNumber = this.serialNumbersList[0].value;
							this.serialNumberChange.emit(this.serialNumber);
							break;
						default:
							this.toast.info('More than one serial number found, please select one');
					}

				});
			};
			reader.onloadstart = () => this.nav.startLoading();
			reader.onloadend = () => this.nav.stopLoading();

			reader.readAsDataURL(file);
		}
	}

	public captureBarcode(): void {
		this.cameraInput.nativeElement.click();
	}
}
