<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<div fxLayout="column" fxLayoutGap="1rem">
	<div class="install-action-container mat-elevation-z2" *ngIf="!isInProgress" fxLayout="column" fxLayoutGap="1rem" fxLayoutAlign="start center">

		<app-pre-check *ngIf="conf.preCheck; else noPreCheck" (begin)="clickToBegin()" [install]="install"
			[customSurvey]="customSurvey" [solarInstallPreCheck]="solarInstallPreCheck">
		</app-pre-check>

		<ng-template #noPreCheck>
			<h5>Please confirm that you are on site and are ready to begin this {{ install.type_id | installType }}</h5>
			<button mat-flat-button color="primary" type="button" class="icon-inside" (click)="clickToBegin()">
				<fa-icon icon="stopwatch"></fa-icon>
				<span>Begin {{ install.type_id | installType }}</span>
			</button>
		</ng-template>

	</div>

	<!-- Begin Tabs Container -->
	<div class="install-action-container mat-elevation-z2" *ngIf="isInProgress">
		<mat-tab-group>
			<mat-tab label="Service Info" *ngIf="conf.serviceAssets && serviceAssets?.enabled && serviceAssets.attributes?.length">
				<app-service-assets [install]="install" [stockRequirements]="serviceAssets.attributes"></app-service-assets>
			</mat-tab>
			<mat-tab label="Layer 3 Details" *ngIf="layer3Enabled">
				<app-pppoe-details [install]="install"></app-pppoe-details>
			</mat-tab>
			<mat-tab label="{{ fsanPhrase }} Validation" *ngIf="isNotRepair">
				<app-serial-number #part [install]="install"></app-serial-number>
			</mat-tab>
			<mat-tab label="Port Number" *ngIf="showPortNumber">
				<app-port-number [install]="install"></app-port-number>
			</mat-tab>
			<mat-tab label="Image Uploads">
				<app-install-image-uploads #part [install]="install"></app-install-image-uploads>
			</mat-tab>
			<mat-tab label="Work Order Assets" *ngIf="conf.stockRequired && stockRequirements?.enabled && stockRequirements.attributes?.length">
				<app-install-stock [install]="install" [stockRequirements]="stockRequirements.attributes"></app-install-stock>
			</mat-tab>
			<mat-tab label="Rica" *ngIf="conf.showRICA && isNotRepair">
				<app-rica #part [install]="install"></app-rica>
			</mat-tab>
			<mat-tab label="Status" *ngIf="conf.displayInstallDiscovery">
				<app-install-status [install]="install"></app-install-status>
			</mat-tab>
		</mat-tab-group>
	</div>
	<div class="install-action-container mat-elevation-z2" *ngIf="isInProgress && hasErrors">
		<p *ngFor="let message of errorMessages" class="alert alert-danger fsan-alert install-validation">{{message}}</p>
		<p *ngIf="!canEdit" class="alert alert-danger begin-install-error" fxLayout fxLayoutGap="space-between">
			<span>Error: You are too far from installation location to Finish your Install</span>
			<fa-icon icon="sync-alt" class="action-icon" matTooltip="Refresh Location" (click)="refreshLocation()"></fa-icon>
		</p>
	</div>

	<div class="install-action-container mat-elevation-z2 signature-form" fxLayout="column" fxLayoutGap="1rem" *ngIf="isInProgress && !hasErrors">
		<mat-checkbox>I, the client/person acting on behalf of client confirm that the installation took place.
			<br><span *ngIf="!isAmerican">No services (i.e DStv) were affected during the installation.</span>
		</mat-checkbox>
		<mat-checkbox *ngIf="!isNidInstallation">I am happy with the location the ONT is installed.</mat-checkbox>
		<div *ngIf="isNidInstallation">
			<mat-checkbox>Are you (the client) happy with the installation?</mat-checkbox>
			<mat-checkbox>Are you (the client) happy with the location of the Router WiFi Device?</mat-checkbox>
		</div>

		<app-signature-field></app-signature-field>
		<div *ngIf="hasMultipleFinishStatuses">
			<label>Select Finish Status From Below</label>
			<mat-radio-group
					aria-labelledby="radio-group-label"
					class="radio-group"
					[(ngModel)]="finishStatusId">
				<mat-radio-button class="radio-button" *ngFor="let status of finishStatuses" [value]="status.value.toString()">
					{{ translate(status.key) }}
				</mat-radio-button>
			</mat-radio-group>
		</div>
		<div fxLayout="row wrap" fxLayoutGap="1rem" fxLayoutAlign="center" class="wrapped-button-container">
			<button mat-flat-button color="primary" class="icon-inside" (click)="clearSignature()">
				<fa-icon icon="broom"></fa-icon>
				<span>Clear Signature</span>
			</button>
			<button mat-flat-button color="primary" class="icon-inside" [disabled]="!hasSignature || !isFsanValid()" (click)="submitSignature()">
				<fa-icon icon="clipboard-check"></fa-icon>
				<span>Finish {{ install.type_id | installType }}</span>
			</button>
			<span class="alert alert-warning" *ngIf='!disableFsanValidation'>Note: {{ fsanPhrase }} must be valid before installation can be completed</span>
		</div>
	</div>
</div>
