import { filterNonNil, WithDestroy } from '@aex/ngx-toolbox';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class FrameworkService extends WithDestroy() {

	private readonly routeDataStream = this.noZombie(this.router.events).pipe(
			filter(event => event instanceof NavigationEnd),
			map(() => {
				let child = this.router.routerState.root.firstChild;
				while (child)
					if (child.firstChild)
						child = child.firstChild;
					else
						return child.snapshot.data;

				return null;
			}),
			filterNonNil(),
	);

	private readonly titleSubject = new BehaviorSubject<string>(null);
	public readonly titleStream = this.titleSubject.asObservable().pipe(filterNonNil());

	constructor(
			private readonly router: Router,
	) {
		super();

		this.routeDataStream.subscribe(({title}) => this.titleSubject.next(title));
	}

	public set title(value: string) {
		this.titleSubject.next(value);
	}
}