import { Component, Input } from '@angular/core';
import { IInstall, IStock } from '../../../../services/types';

@Component({
	selector: 'app-service-assets',
	template: `
		<app-assets [install]="install" [assetRequirements]="stockRequirements" [assetType]="assetType" [dropdown]="powerTypes"></app-assets>
	`,
})
export class ServiceAssetsComponent {

	@Input() public readonly install: IInstall;
	@Input() public readonly stockRequirements: IStock[];

	public assetType = 'Service Assets';

	public powerTypes = [
		'Indoor',
		'Indoor-Two Prong',
		'Outdoor',
		'PUD Power Alfa Collar',
		'PUD Power Triad',
		'Pending',
		'No Power',
		'Customer Power/Type Unknown',
		'PUD Power',
		'PUD Power LP Collar',
	];

}
