<form #form=ngForm fxLayout="column" fxLayoutGap="1rem" (ngSubmit)="form.valid && onSubmit()" class="loader-container">
	<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

	<mat-form-field *ngIf="conf.includeISPRef">
		<input matInput type="text" name="ispReference" [ngModel]="install.extraInfo?.ispReference" placeholder="ISP Reference" #ispReferenceField=ngModel required/>
		<mat-error *ngIf="ispReferenceField.errors?.required">ISP Reference is required</mat-error>
	</mat-form-field>

	<mat-button-toggle-group fxFlexAlign="center" [(value)]="manualCapture">
		<mat-button-toggle [value]="true">Manual</mat-button-toggle>
		<mat-button-toggle [value]="false">Capture</mat-button-toggle>
	</mat-button-toggle-group>

	<div *ngIf="manualCapture; else notManualCapture">
		<mat-form-field *ngIf="this.disableFsanValidation">
			<input matInput type="text" [ngModel]="install.extraInfo?.serialNumber" name="serialNumber"
				   placeholder="Enter {{ fsanPhrase }} Number" #serialNumberField=ngModel required>
			<mat-error *ngIf="serialNumberField.errors?.required">Serial Number is required</mat-error>
		</mat-form-field>
		<mat-form-field *ngIf="!this.disableFsanValidation">
			<input matInput type="text" [ngModel]="install.extraInfo?.serialNumber" name="serialNumber"
				   placeholder="Enter {{ fsanPhrase }} Number" #serialNumberField=ngModel [pattern]="ontSerialPattern" required>
			<mat-error *ngIf="serialNumberField.errors?.pattern">Serial Number is the wrong format</mat-error>
			<mat-error *ngIf="serialNumberField.errors?.required">Serial Number is required</mat-error>
			<mat-error *ngIf="serialNumberInvalid">Serial Number is not valid</mat-error>
			<mat-hint *ngIf="serialNumberValid" class="icon-inside success">
				<fa-icon icon="check"></fa-icon>
				<span>Serial Number is valid</span>
			</mat-hint>
		</mat-form-field>
	</div>
	<ng-template #notManualCapture>
		<input type="file" name="cameraInput" capture="camera" #cameraInput accept="file_extension" class="hidden-file-input" (change)="onBarcodeFileSelected($event)"/>

		<div fxLayout fxLayoutGap="1rem" fxLayoutAlign="start center" *ngIf="serialNumbersList.length; else noSerials">
			<mat-form-field fxFlex>
				<mat-select name="serialNumber" placeholder="Select FSAN Number" [ngModel]="install.extraInfo?.serialNumber" #serialNumberField=ngModel required>
					<mat-option *ngFor="let sn of serialNumbersList" [value]="sn.value">{{sn.value}}</mat-option>
				</mat-select>
				<mat-error *ngIf="serialNumberField.errors?.required">Serial Number is required</mat-error>
				<mat-error *ngIf="serialNumberInvalid">Serial Number is not valid</mat-error>
				<mat-hint *ngIf="serialNumberValid" class="icon-inside success">
					<fa-icon icon="check"></fa-icon>
					<span>Serial Number is valid</span>
				</mat-hint>
			</mat-form-field>

			<button mat-mini-fab color="primary" type="button" (click)="captureBarcode()">
				<fa-icon icon="camera" size="lg"></fa-icon>
			</button>
		</div>

		<ng-template #noSerials>
			<button mat-flat-button color="primary" type="button" class="icon-inside" fxFlexAlign="center" (click)="captureBarcode()">
				<fa-icon icon="camera"></fa-icon>
				<span>Capture Barcode</span>
			</button>
		</ng-template>

	</ng-template>

	<div *ngIf="displayFsanFootnote">
		<p>Please note: The {{ fsanPhrase }} will validate 5-10 min after being powered up and connected to the network.</p>
	</div>

	<button mat-flat-button color="primary" fxFlexAlign="center" class="save-button icon-inside" type="submit"
			[disabled]="form.invalid">
		<fa-icon icon="cloud-upload-alt"></fa-icon>
		<span>{{!serialNumberValid ? 'Validate & Save' : 'Save'}}</span>
	</button>
</form>
