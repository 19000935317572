import { filterNonNil } from '@aex/ngx-toolbox';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ParamMetaData } from '../_shared/param-meta-data';
import { UtilityApi } from './api';
import { IProcessBarcodeResponse, ISerialNumber } from './types';

@Injectable({providedIn: 'root'})
export class UtilityService {

	private readonly scrollPositionSubject = new Subject<number>();
	public readonly scrollPositionStream = this.scrollPositionSubject.asObservable().pipe(filterNonNil());

	constructor(
			private readonly http: HttpClient,
	) {}

	public processBarcode(data: FormData): Observable<ISerialNumber[]> {
		return this.http.post<IProcessBarcodeResponse>(UtilityApi.processBarcode, data, {
			params: new ParamMetaData({handleError: 'files'}),
		}).pipe(
				map(r => r.list.filter(s => s.serial_number && s.value && ONT_REGEX.test(s.value))),
		);
	}

	public scrollToTop(): void {
		this.scrollPositionSubject.next(0);
	}
}

export const ONT_PATTERN = '([A-Z0-9]{4}|[0-9]{8})[A-Z0-9]{8}';
const ONT_REGEX = new RegExp(ONT_PATTERN);