import { appRoute, HttpErrorMessages } from '@aex/ngx-toolbox';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';


export const HTTP_ERROR_MESSAGES: HttpErrorMessages = {
	assets: {
		[HttpStatusCode.NotFound]: null,
	},
	authentication: {
		[HttpStatusCode.Unauthorized]: (error: HttpErrorResponse) =>
			error.error?.is_locked_out ? getTimeRemaining(error.error.lockout_reset_date) : 'Incorrect Username or Password.',
		[HttpStatusCode.NotFound]: 'User not found.',
	},
	installs: {
		[HttpStatusCode.Unauthorized]: 'You are unauthorized to view these work orders',
		[HttpStatusCode.NotFound]: 'Install not found or invalid.',
		[HttpStatusCode.BadRequest]: (error: HttpErrorResponse) => error.error?.errors?.length ? error.error.errors[0] : error.error,
	},
	files: {
		[HttpStatusCode.NotFound]: 'File not found or invalid.',
		[HttpStatusCode.PayloadTooLarge]: 'File size too large.',
	},
};

function getTimeRemaining(resetDate: string): string {
	const total = new Date(resetDate).valueOf() - new Date().valueOf();
	const seconds = Math.floor((total / 1000) % 60);
	const minutes = Math.floor((total / 1000 / 60) % 60);

	return `You have been locked out, Please try again in ${minutes} minutes and ${seconds} seconds...`;
}

export const APP_ROUTES = {
	login: appRoute('login'),
	installs: appRoute('installs'),
	loginRedirect: appRoute('login-redirect'),
	install: appRoute('install'),
	managePremises: appRoute('manage-premises'),
};

export const MATERIAL_TIME_PICKER_THEME: NgxMaterialTimepickerTheme = {
	container: {
		buttonColor: '#3949AB',
	},
	dial: {
		dialBackgroundColor: '#3949AB',
	},
	clockFace: {
		clockFaceBackgroundColor: '#3949ab',
		clockFaceTimeInactiveColor: '#fff',
		clockFaceInnerTimeInactiveColor: '#fff',
		clockFaceTimeDisabledColor: '#6067bd',
		clockFaceTimeActiveColor: '#3949ab',
		clockHandColor: '#fff',
	},
};

export const HEADER_LOCATION = 'Location';
export const LOCATION_RICA = 'rica';
