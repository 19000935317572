<h2 mat-dialog-title>{{ workOrder.type }} - {{ workOrder.status }}</h2>
<mat-dialog-content class="mat-typography">
	<h3><strong>Details</strong></h3>
	<p *ngIf="workOrder.reference"><strong>Ref:</strong> {{workOrder.reference}}</p>
	<p *ngIf="workOrder.created_at"><strong>Created:</strong> {{workOrder.created_at | nonIsoDatePipe}}</p>
	<p *ngIf="workOrder.created_by"><strong>Created By:</strong> {{workOrder.created_by}}</p>
	<p *ngIf="workOrder.external_reference"><strong>External Ref:</strong> {{workOrder.external_reference}}</p>
	<p *ngIf="workOrder.assigned"><strong>Assigned to:</strong> {{workOrder.assigned}}</p>
	<p *ngIf="workOrder.assigned_user"><strong>Assigned User:</strong> {{workOrder.assigned_user}}</p>
	<p *ngIf="workOrder.updated_at"><strong>Last Updated:</strong> {{workOrder.updated_at | nonIsoDatePipe}}</p>
	<p *ngIf="workOrder.last_comment && hideLastComment"><strong>Last Comment: </strong> {{workOrder.last_comment}}</p>

	<h3><strong>History</strong></h3>
	<div *ngIf="workOrderHistory?.length > 0, else noHistory">
		<div *ngFor="let history of workOrderHistory">
			<div class="panel-padding">
				<mat-expansion-panel class="panel-padding">
					<mat-expansion-panel-header fxLayout fxLayoutGap=".2rem" fxLayoutAlign="space-between center">
						<div fxFlex="1 0 auto">{{ history.comments || (history.modified_date | nonIsoDatePipe) }}</div>
					</mat-expansion-panel-header>

					<ng-template matExpansionPanelContent>
						<div fxLayout="column" fxLayoutGap=".5rem">
							<p><strong>Modified By:</strong> {{history.modified_by}}</p>
							<p><strong>Modified Date:</strong> {{history.modified_date | nonIsoDatePipe}}</p>
							<p *ngIf="history.company_changed">Company changed to <strong>{{history.new_company}}</strong></p>
							<p *ngIf="history.installer_changed">Installer changed to <strong>{{history.new_installer}}</strong></p>
							<p *ngIf="history.person_changed">Person changed to <strong>{{history.new_person}}</strong></p>
							<p *ngIf="history.status_changed">Status changed to <strong>{{history.new_status}}</strong></p>
							<p *ngIf="hideLastComment"><strong>Comment:</strong> {{history.comments}}</p>
						</div>
					</ng-template>
				</mat-expansion-panel>
			</div>
		</div>
	</div>
	<ng-template #noHistory>
		No History to Display
	</ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
