import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { head } from 'lodash';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ParamMetaData } from '../_shared/param-meta-data';
import { ServiceAssetsApi, StockApi, WorkOrderAssetsApi } from './api';
import { IStockRequirement } from './types';
import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class StockService {

	constructor(
		private readonly http: HttpClient,
		private readonly configService: ConfigService,
	) { }

	private _stockRequirement: IStockRequirement;
	private _assetRequirement: IStockRequirement;

	public get assetsMeta(): ParamMetaData { return new ParamMetaData({ handleError: 'assets' }); }

	private get useAssetIntegrationService():boolean {
		return this.configService.config.useAssetIntegrationService;
	}

	private static is404Error(error: any): Observable<{enabled: boolean, attributes: any[]}> {
		if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.NotFound)
			return of({
				enabled: false,
				attributes: [],
			});
		else
			return throwError(error);
	}

	private mapStockAttributes(requirement: IStockRequirement): IStockRequirement {
		const attributes: any = requirement.attributes.map((attribute: any) => {
			if (attribute.description !== null && attribute.description !== undefined) {
				const split = attribute.description.split(':');
				return {
					...attribute,
					description: head(split),
					type: split.length > 1 ? split[1] : 'text',
				};
			} else
				return { ...requirement };
		});

		return { ...requirement, attributes };
	}

	public getStockRequirements(): Observable<IStockRequirement> {
		return this._stockRequirement
			? of(this._stockRequirement)
			: this.http.get<IStockRequirement>(StockApi.stock, { params: this.assetsMeta }).pipe(
				catchError(error => {
					return StockService.is404Error(error);
				}),
				map(this.mapStockAttributes),
				tap(result => this._stockRequirement = result),
			);
	}

	public getServiceAssets(): Observable<IStockRequirement> {
		return this._assetRequirement
			? of(this._assetRequirement)
			: this.http.get<IStockRequirement>(ServiceAssetsApi.serviceAssetAttributes, { params: this.assetsMeta }).pipe(
				catchError(error => {
					return StockService.is404Error(error);
				}),
				map(this.mapStockAttributes),
				tap(result => this._assetRequirement = result),
			);
	}

	public getInstallTypeValues(workOrderId: string): Observable<IStockRequirement> {
		return this.http.get<IStockRequirement>(
			WorkOrderAssetsApi.workOrderAssets(workOrderId, 'install-type', this.useAssetIntegrationService),
			{ params: this.assetsMeta },
		).pipe(
			catchError(error => StockService.is404Error(error)),
		);
	}

	public getStockValues(workOrderId: string): Observable<IStockRequirement> {
		return this.http.get<IStockRequirement>(StockApi.workOrder(workOrderId, this.useAssetIntegrationService), {
			params: this.assetsMeta,
		}).pipe(
			catchError(error => StockService.is404Error(error)),
			map(this.mapStockAttributes),
		);
	}

	public getAssetValues(serviceId: string): Observable<IStockRequirement> {
		return this.http.get<IStockRequirement>(ServiceAssetsApi.serviceAssets(serviceId, this.useAssetIntegrationService), {
			params: this.assetsMeta,
		}).pipe(
			map(this.mapStockAttributes),
		);
	}

	public saveStock(workOrderId: string, stock: any[]): Observable<void> {
		const date = (new Date()).toISOString();
		const payload = {
			asset: {
				created_at: date,
				deleted_at: date,
				work_order_id: workOrderId,
				attributes: stock,
			},
		};
		return this.http.post<void>(StockApi.workOrder(workOrderId), payload, {
			params: this.assetsMeta,
		});
	}

	public saveAssets(serviceId: string, stock: any[]): Observable<void> {
		const date = (new Date()).toISOString();
		const payload = {
			asset: {
				created_at: date,
				deleted_at: date,
				service_id: serviceId,
				attributes: stock,
			},
		};
		return this.http.post<void>(ServiceAssetsApi.serviceAssets(serviceId, this.useAssetIntegrationService), payload, {
			params: this.assetsMeta,
		});
	}
}

@Injectable({ providedIn: 'root' })
export class GetStockRequirementsResolver implements Resolve<IStockRequirement> {

	constructor(
		private readonly service: StockService,
		private readonly conf: ConfigService,
	) { }

	public resolve(): Observable<IStockRequirement> {
		if (this.conf.config.stockRequired)
			return this.service.getStockRequirements().pipe(
				catchError(() => of(null)),
			);
		else
			return null;
	}

}

@Injectable({ providedIn: 'root' })
export class GetServiceAssetsResolver implements Resolve<IStockRequirement> {

	constructor(
		private readonly service: StockService,
		private readonly conf: ConfigService,
	) { }

	public resolve(): Observable<IStockRequirement> {
		if (this.conf.config.serviceAssets)
			return this.service.getServiceAssets();
		else
			return null;
	}
}
