import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
	templateUrl: './login-redirect.component.html',
	styleUrls: ['./login-redirect.component.scss'],
})
export class LoginRedirectComponent {

	constructor(
			authService: AuthService,
			route: ActivatedRoute,
	) {
		route.params.subscribe(({returnUrl}) => authService.returnUrl = returnUrl);
	}
}
