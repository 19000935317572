<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<!--Filter bar-->
<div class="header-bar mat-elevation-z2">
    <mat-grid-list cols="4" rowHeight="60px">
        <mat-grid-tile [colspan]="4" [rowspan]="1" [style.background]="'white'">
            <mat-button-toggle-group [(ngModel)]="filter.dateFilterType" (change)="filterChanged()">
                <mat-button-toggle [value]="DateFilterType.CURRENT_WEEK">MY WEEK</mat-button-toggle>
                <mat-button-toggle [value]="DateFilterType.CUSTOM">CUSTOM DATES</mat-button-toggle>
                <mat-button-toggle [value]="DateFilterType.ALL">ALL</mat-button-toggle>
            </mat-button-toggle-group>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="4" [rowspan]="1" [style.background]="'white'">
            <mat-button-toggle-group [(ngModel)]="filter.installType" (change)="filterChanged()">
                <mat-button-toggle [value]="InstallType.INSTALL">INSTALLS</mat-button-toggle>
                <mat-button-toggle  [value]="InstallType.REPAIR">{{ repairPhrase }}</mat-button-toggle>
                <mat-button-toggle *ngIf="showPreOrder" [value]="InstallType.PREORDER">PRE-ORDERS</mat-button-toggle>
                <mat-button-toggle *ngIf="showRelocation" [value]="InstallType.RELOCATION">RELOCATIONS</mat-button-toggle>
                <mat-button-toggle *ngIf="showNidInstalls" [value]="InstallType.NIDINSTALL">NID INSTALLS</mat-button-toggle>
                <mat-button-toggle [value]="InstallType.ALL">ALL</mat-button-toggle>
            </mat-button-toggle-group>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="4" [rowspan]="1" [style.background]="'white'">
            <mat-button-toggle-group [(ngModel)]="filter.installType" (change)="filterChanged()">

                <mat-button-toggle *ngIf="showPremiumRepair" [value]="InstallType.PREMIUM_SUPPORT_REPAIR">{{ premiumRepairPhrase }}</mat-button-toggle>
                <mat-button-toggle *ngIf="showFault" [value]="InstallType.FAULT">FAULTS</mat-button-toggle>
                <mat-button-toggle *ngIf="showPremiumFault" [value]="InstallType.PREMIUM_SUPPORT_FAULT">PREMIUM-FAULTS</mat-button-toggle>
            </mat-button-toggle-group>
        </mat-grid-tile>
    </mat-grid-list>

    <div class="date-selected-value" *ngIf="filter.dateFilterType === DateFilterType.CURRENT_WEEK">
        Showing installs from: <em>{{ filter.startDate | nonIsoDatePipe }}</em> to <em>{{ filter.endDate | nonIsoDatePipe }}</em>
    </div>
    <div *ngIf="filter.dateFilterType === DateFilterType.CUSTOM" fxLayout fxLayoutGap="1rem" fxLayoutAlign="center">

        <mat-form-field fxFlex="1 1 9em">
            <input matInput [matDatepicker]="startPicker" placeholder="From Date"
                   (dateChange)="filterChanged()"
                   (click)="startPicker.open()"
                   [(ngModel)]="filter.startDate"
                   [matDatepickerFilter]="businessDayFilter" />
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field fxFlex="1 1 9em">
            <input matInput [matDatepicker]="endPicker" placeholder="To Date"
                   (dateChange)="filterChanged()"
                   (click)="endPicker.open()"
                   [min]="filter.startDate"
                   [(ngModel)]="filter.endDate"
                   [matDatepickerFilter]="businessDayFilter" />
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>
    </div>
</div>

<!--Dashboard Summary-->
<div class="dashboard-container mat-elevation-z2" fxLayout fxLayoutAlign="space-around">
    <div class="dash-item" [ngClass]="{ active: statusFilter.get(InstallStatus.IN_PROGRESS) }"
         (click)="filterByStatus(InstallStatus.IN_PROGRESS)">
        <span class="dash-label">In Progress</span>
        <div class="radial-indicator progress">
            <span>{{ installs?.aggregates.get(InstallStatus.IN_PROGRESS) || 0 }}</span>
        </div>
    </div>
    <div class="dash-item" [ngClass]="{ active: statusFilter.get(InstallStatus.OVERDUE) }"
         (click)="filterByStatus(InstallStatus.OVERDUE)">
        <span class="dash-label">Overdue</span>
        <div class="radial-indicator overdue">
            <span>{{ installs?.aggregates.get(InstallStatus.OVERDUE) || 0 }}</span>
        </div>
    </div>
    <div class="dash-item" [ngClass]="{ active: statusFilter.get(InstallStatus.PENDING) }"
         (click)="filterByStatus(InstallStatus.PENDING)">
        <span class="dash-label">Pending</span>
        <div class="radial-indicator pending">
            <span>{{ installs?.aggregates.get(InstallStatus.PENDING) || 0 }}</span>
        </div>
    </div>
    <div class="dash-item" [ngClass]="{ active: statusFilter.get(InstallStatus.AWAITING) }"
         (click)="filterByStatus(InstallStatus.AWAITING)">
        <span class="dash-label">Config</span>
        <div class="radial-indicator awaiting">
            <span>{{ installs?.aggregates.get(InstallStatus.AWAITING) || 0 }}</span>
        </div>
    </div>
</div>

<!-- Refresh My Installs -->
<div class="refresh-installs">
    <button mat-flat-button color="primary" (click)="refreshInstalls()" class="icon-inside">
        <fa-icon icon="sync-alt"></fa-icon>
        <span>Refresh {{ this.filter.installType | installType:true }}</span>
    </button>
</div>

<!-- Cards which show today's installs schedule -->
<app-install-summary *ngFor="let install of filteredInstalls" [install]="install"
                     [onlyOne]="filteredInstalls?.length === 1"></app-install-summary>

<div *ngIf="!this.filteredInstalls?.length" class="mat-elevation-z2 no-installs install-empty-text" fxLayout="row wrap"
     fxLayoutGap=".5em" fxLayoutAlign="center center">
    <span>No</span>
    <span *ngIf="!!installStatus"><b>{{ installStatus }}</b></span>
    <span>{{ filter.installType | installType:true }}</span>
    <span>found</span>
    <span *ngIf="filter.startDate && filter.endDate">
        from <em>{{ filter.startDate | nonIsoDatePipe }}</em>
        to <em>{{ filter.endDate | nonIsoDatePipe }}</em>
    </span>
</div>
