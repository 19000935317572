import { AexNgToolboxModule, DEFAULT_LOADER_CONFIG, MockModule, NavigationService, TOASTER_CONFIG } from '@aex/ngx-toolbox';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { NgxFilesizeModule } from 'ngx-filesize';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { TableModule } from 'primeng/table';
import { environment } from '../environments/environment';
import { MockRegistrations } from '../mock/mock';
import { DateFnsDateAdapter, MATERIAL_DATE_FORMAT } from './_shared/date-fns-adapter';
import { GlobalErrorHandler } from './_shared/error-handler';
import { ServiceWorkerInterceptor } from './_shared/interceptors';
import { HTTP_ERROR_MESSAGES } from './_shared/types';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClientDetailsComponent } from './components/installs/client-details.component';
import { AssetsComponent } from './components/installs/install-detail/install-begin/assets.component';
import { ImageUploadComponent } from './components/installs/install-detail/install-begin/image-upload/image-upload.component';
import { InstallBeginComponent } from './components/installs/install-detail/install-begin/install-begin.component';
import { InstallImageUploadsComponent } from './components/installs/install-detail/install-begin/install-image-uploads.component';
import { InstallStockComponent } from './components/installs/install-detail/install-begin/install-stock.component';
import { PppoeDetailsComponent } from './components/installs/install-detail/install-begin/pppoe-details.component';
import { PreCheckComponent } from './components/installs/install-detail/install-begin/pre-check.component';
import { RicaComponent } from './components/installs/install-detail/install-begin/rica.component';
import { SerialNumberComponent } from './components/installs/install-detail/install-begin/serial-number.component';
import { ServiceAssetsComponent } from './components/installs/install-detail/install-begin/service-assets.component';
import { SignatureFieldComponent } from './components/installs/install-detail/install-begin/signature-field.component';
import { InstallCancelComponent } from './components/installs/install-detail/install-cancel.component';
import { InstallDetailComponent } from './components/installs/install-detail/install-detail.component';
import { InstallHistoryDetailedComponent } from './components/installs/install-detail/install-history-detailed.component';
import { InstallHistoryComponent } from './components/installs/install-detail/install-history.component';
import { InstallHoldComponent } from './components/installs/install-detail/install-hold.component';
import { InstallRescheduleComponent } from './components/installs/install-detail/install-reschedule.component';
import { InstallSummaryComponent } from './components/installs/install-summary.component';
import { InstallsComponent } from './components/installs/installs.component';
import { LoginRedirectComponent } from './components/login-redirect.component';
import { LoginComponent } from './components/login.component';
import { MaterialModule } from './material.module';
import { InstallDistancePipe } from './pipes/install.distance.pipe';
import { InstallTypePipe } from './pipes/install.type.pipe';
import { AuthInterceptor, MSAL_CLIENT_APP } from './services/auth.service';
import { ConfigService } from './services/config.service';
import { PortNumberComponent } from './components/installs/install-detail/install-begin/port-number.component';
import { InstallStatusComponent } from './components/installs/install-detail/install-status.component';
import { InstallReassignComponent } from './components/installs/install-detail/install-reassign.component';
import { NonIsoDatePipe } from './pipes/non-iso-date.pipe';
import { ApiInterceptor } from './services/api-interceptor.service';
import { ManagePremisesComponent } from './components/manage-premises/manage-premises.component';
import { BarcodeScannerComponent } from './components/_shared/barcode-scanner/barcode-scanner.component';
import { PremisesComponent } from './components/manage-premises/premises/premises.component';

@NgModule({
	declarations: [
		AppComponent,
		InstallsComponent,
		LoginComponent,
		InstallSummaryComponent,
		InstallDetailComponent,
		InstallBeginComponent,
		InstallRescheduleComponent,
		InstallCancelComponent,
		ClientDetailsComponent,
		SerialNumberComponent,
		SignatureFieldComponent,
		InstallImageUploadsComponent,
		InstallStockComponent,
		RicaComponent,
		InstallTypePipe,
		InstallDistancePipe,
		PreCheckComponent,
		ImageUploadComponent,
		InstallHoldComponent,
		PppoeDetailsComponent,
		LoginRedirectComponent,
		AssetsComponent,
		ServiceAssetsComponent,
		InstallHistoryComponent,
		InstallHistoryDetailedComponent,
		PortNumberComponent,
		InstallStatusComponent,
		InstallReassignComponent,
		NonIsoDatePipe,
		ManagePremisesComponent,
		PremisesComponent,
		BarcodeScannerComponent,
	],
	imports: [
		BrowserModule,
		ReactiveFormsModule,
		HttpClientModule,
		NgxFilesizeModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		MaterialModule,
		FormsModule,
		AngularSignaturePadModule,
		FontAwesomeModule,
		NgxMaterialTimepickerModule,
		FlexLayoutModule,
		TableModule,
		ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
		MockModule.forRoot({ enabled: environment.mocksEnabled }),
		NgxUiLoaderModule.forRoot(DEFAULT_LOADER_CONFIG),
		ToastrModule.forRoot(TOASTER_CONFIG),
		AexNgToolboxModule.forRoot({
			httpTimeout: environment.httpTimeout,
			httpErrorMessages: HTTP_ERROR_MESSAGES,
		}),
		MsalModule.forRoot(MSAL_CLIENT_APP, null, null),
	],
	providers: [
		ConfigService,
		{
			provide: APP_INITIALIZER,
			deps: [ConfigService, NavigationService, MockRegistrations],
			useFactory: (cs: ConfigService) => () => cs.loadAppConfig().toPromise(),
			multi: true,
		},
		{ provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ServiceWorkerInterceptor, multi: true },
		{ provide: DateAdapter, useClass: DateFnsDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATE_FORMAT },
		{ provide: ErrorHandler, useClass: GlobalErrorHandler },
	],
	bootstrap: [
		AppComponent,
		MsalRedirectComponent,
	],
})
export class AppModule {

	constructor(library: FaIconLibrary) {
		library.addIconPacks(fas, far);
	}

}

