import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from '../services/config.service';

/*
 * Convert install distance into text.
 * Usage:

 * Example:
 *   {{ 0.1 | installDistance}}
 *   formats to: 100 meters
 *   {{ 1.1 | installDistance}}
 *   formats to: 1.1 km
*/
@Pipe({name: 'installDistance'})
export class InstallDistancePipe implements PipeTransform {

	constructor(
		private readonly conf: ConfigService,
	) {	}

	public transform(distance: number): string {
		if (distance < 1)
			return `${ distance * 1000 } ${this.conf.config.customPhrases?.distanceMetric ? 'ft' : 'm'} `;
		else
			return `${ distance } ${this.conf.config.customPhrases?.distanceMetric ?? 'km'}`;
	}
}
